import React, { useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Selector from "../../../../../../../../components/selector";
import Style from "./ContainerSelectionDropdown.module.css";
import useAvoidRacingAPIHelper from "../../../../../../../../hooks/use-avoid-racing-api-helper";
import { useXemelgoAppsyncClient } from "../../../../../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../../../../../services/xemelgo-service";
import formatText from "../../../../../../../../utils/format-text";
import useTransferOrderConfigContext from "../../contexts/transfer-order-config-context";
import AutoSizeTextArea from "../../../../../../../../components/AutoSizeTextArea/AutoSizeTextArea";

export const ContainerSelectionDropdown = ({
  transferOrderGroupName,
  onSelectContainer,
  onChangeNewContainer,
  newContainer
}) => {
  const { customText, containerAssociationOptions } = useTransferOrderConfigContext();
  const { existingContainerDisabled, manualTagEntry, tagFieldLabel } = containerAssociationOptions;
  const appsyncItemClient = useXemelgoAppsyncClient().getItemClient();
  const itemClient = useXemelgoClient().getItemClient();
  const executeSearchFn = useAvoidRacingAPIHelper();
  const [searchInput, setSearchInput] = useState({});
  const [isNewContainer, setIsNewContainer] = useState(existingContainerDisabled);

  const searchFn = async (searchString) => {
    const containerOptions = (await itemClient.getItemsOfClassByTextSearch(["Container"], searchString))
      .map((item) => {
        return {
          label: item.identifier,
          value: {
            identifier: item.identifier,
            itemId: item.id,
            itemTypeId: item.type.id,
            itemTypeIdentifier: item.type.identifier,
            vid: item.sensor_profile_vid,
            sensorProfileId: item.sensorProfile?.id
          }
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    return containerOptions;
  };

  const createNewContainerIdentifier = async () => {
    setIsNewContainer(true);
    onSelectContainer();
    setSearchInput({
      label: `New ${formatText(customText.container, ["capitalize"])}`
    });

    const groupName = `${transferOrderGroupName}-${formatText(customText.container, ["capitalize"])}`;
    onChangeNewContainer({
      identifier: (await appsyncItemClient.createIdentifiers(groupName))[0]
    });
  };

  return (
    <div>
      <div>
        {!existingContainerDisabled && (
          <>
            <div className={Style.label}>
              <p className={Style.bold_text}>{`${customText.containerIdentifierLabel}:`}</p>
              <p className={Style.required_asterisk}>*</p>
            </div>
            <Selector
              placeholder="Select..."
              value={searchInput}
              resetOnClose
              onSelect={(newItem) => {
                setSearchInput(newItem);
                onSelectContainer(newItem.value);
                onChangeNewContainer({});
                setIsNewContainer(false);
              }}
              searchFn={(input) => {
                return executeSearchFn(searchFn(input));
              }}
            />
            {!isNewContainer && (
              <button
                type="button"
                className={Style.clickable_text}
                onClick={createNewContainerIdentifier}
                disabled={!transferOrderGroupName}
              >
                {`Create a new ${customText.container}`}
              </button>
            )}
          </>
        )}
      </div>
      {isNewContainer && (
        <div className={Style.new_container_section}>
          {!existingContainerDisabled && (
            <div className={Style.new_container_container}>
              <p className={Style.bold_text}>{`New ${customText.containerIdentifierLabel}:`}</p>
              <div className={Style.container_identifier}>
                {newContainer?.identifier ? (
                  <p>{newContainer?.identifier}</p>
                ) : (
                  <Skeleton
                    height={20}
                    width={120}
                  />
                )}
              </div>
            </div>
          )}
          {manualTagEntry && (
            <div>
              <div className={Style.label}>
                <p className={Style.bold_text}>{`${tagFieldLabel || "RFID Tag"}:`}</p>
                <p className={Style.required_asterisk}>*</p>
              </div>
              <AutoSizeTextArea
                value={newContainer?.vid}
                onChangeText={(tag) => {
                  onChangeNewContainer({
                    identifier: existingContainerDisabled ? tag : newContainer?.identifier,
                    vid: tag
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ContainerSelectionDropdown.defaultProps = {
  transferOrderGroupName: "",
  onSelectContainer: () => {},
  onChangeNewContainer: () => {},
  newContainer: {}
};

ContainerSelectionDropdown.propTypes = {
  transferOrderGroupName: PropTypes.string,
  onSelectContainer: PropTypes.func,
  onChangeNewContainer: PropTypes.func,
  newContainer: PropTypes.shape({
    identifier: PropTypes.string,
    vid: PropTypes.string
  })
};
