import React, { useEffect, useMemo } from "react";
import { multipleSortComparison } from "../../../../utils";
import Style from "./ActionsPage.module.css";
import useKioskConfigContext from "../../contexts/kiosk-config-context";
import useKioskStateContext from "../../contexts/kiosk-state-context";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";

export const ActionsPage = () => {
  const { setActionId } = useKioskSearchParams();
  const { actionsMap } = useKioskConfigContext();
  const { setLoading, resetState } = useKioskStateContext();

  useEffect(() => {
    resetState();
  }, []);

  const sortedActions = useMemo(() => {
    return Object.values(actionsMap).sort((a, b) => {
      if (a.index === b.index) {
        return multipleSortComparison(["label"])(a, b);
      }
      return a.index - b.index;
    });
  }, [actionsMap]);

  return (
    <div className={Style.container}>
      <div
        className={`${Style.actions_page_container} ${
          sortedActions.length <= 4 ? Style.actions_page_container_column : Style.actions_page_container_row
        }`}
      >
        {sortedActions.map((action) => {
          return (
            <div
              className={Style.action_button_container}
              key={action.id}
            >
              <button
                type="button"
                key={action.id}
                onClick={() => {
                  setActionId(action.id);
                  setLoading(true);
                }}
                className={Style.action_button}
              >
                {action.label}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
