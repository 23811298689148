import { naturalSort } from "../../../../../../../../common/Utilities";
import { XemelgoService } from "../../../../../../../../services/XemelgoService";
import { OPTION_TEMPLATE_OPTIONS } from "../../../../../../data/constants";
import { getLocationWithDetector } from "../../../../../../utils/location-detector-mapper";

export const defaultFetchData = async (
  userLocation,
  sidePanelAttributes,
  readerLocationOptionCategory,
  locationOptionCategory
) => {
  const locationClient = XemelgoService.getClient().getLocationClient();
  const detectorClient = XemelgoService.getClient().getDetectorClient();

  const newLocationTree = locationClient.getLocationTree(["id", "name", "identifier"]);
  const newDetectorsPromise = detectorClient.listDetectors();
  const promises = [newLocationTree, newDetectorsPromise];

  sidePanelAttributes.forEach((attribute) => {
    if (attribute.optionTemplate) {
      promises.push(getOptionsFromOptionsTemplate(attribute.optionTemplate));
    }
  });

  const [locationTree = {}, detectors = [], ...optionsResponses] = await Promise.all(promises);
  const { locationOptionsMap, readerLocationOptionsMap } = getLocationWithDetector(
    locationTree,
    userLocation,
    detectors
  );
  const newOptionsTemplateMap = optionsResponses.reduce((accumulator, response) => {
    const { id, options } = response;
    accumulator[id] = options;
    return accumulator;
  }, {});

  const newLocationOptions = naturalSort(
    Object.values(locationOptionsMap).filter((location) => {
      return location.category === locationOptionCategory;
    }),
    "label"
  );
  const newReaderOptions = naturalSort(
    Object.values(readerLocationOptionsMap).filter((reader) => {
      return reader.category === readerLocationOptionCategory;
    }),
    "label"
  );

  return {
    newOptionsTemplateMap,
    newLocationOptions,
    newReaderOptions
  };
};

const getOptionsFromOptionsTemplate = async (id) => {
  switch (id) {
    case OPTION_TEMPLATE_OPTIONS.USERS:
      const userClient = XemelgoService.getAppsyncClient().getUserClient();
      const queryUsersResponse = await userClient.queryUsers();
      const { queryUsers = {} } = queryUsersResponse;
      const { users = [] } = queryUsers;
      const userOptions = users.map((user) => {
        const formattedName = `${user.firstName || ""} ${user.lastName || ""}`;
        return {
          id: user.id,
          value: formattedName,
          label: formattedName
        };
      });
      return {
        id,
        options: userOptions
      };
    default:
      return {
        id,
        options: []
      };
  }
};
