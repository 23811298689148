import xemelgoStyle from "../../../../../../../../../../../styles/variable";

export const NOT_SCANNED_STATUS = {
  label: "Not Scanned",
  backgroundColor: xemelgoStyle.theme.NEUTRAL_PRIMARY
};

export const SCANNED_STATUS = {
  label: "Scanned",
  backgroundColor: xemelgoStyle.theme.STATUS_GREEN
};
