import { XemelgoService } from "../../../../../../services/XemelgoService";

/**
 * @param {Array} itemTypeReports - An array of item type reports.
 * @param {string} itemClass - The class of the items to be onboarded.
 * @returns {Promise<Array<{ itemTypeId: string, items: Array<{itemId: string, sensorProfileId: string}> }>>}
 */
export const onboardItems = async (itemTypeReports, itemClass) => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();
  const itemClient = XemelgoService.getClient().getItemClient();

  const { epcToCreatedItemAcc: epcToCreatedItem, epcsToQueryAcc: epcsToQuery } = itemTypeReports.reduce(
    (acc, itemTypeReport) => {
      const { epcToCreatedItemAcc = {}, epcsToQueryAcc = [] } = acc;
      const { epcToItemMap } = itemTypeReport;

      Object.entries(epcToItemMap).forEach(([epc, item]) => {
        if (item.id) {
          // item was retrieved on MainPage when scanning tags
          epcToCreatedItemAcc[epc] = {
            ...item,
            itemId: item.id,
            sensorProfileId: item.sensorProfileId
          };
        } else {
          epcsToQueryAcc.push(epc);
        }
      });

      return { epcToCreatedItemAcc, epcsToQueryAcc };
    },
    {}
  );

  if (epcsToQuery.length) {
    const existingItems = await itemClient.getItemsBySensorProfileVids(epcsToQuery);

    existingItems.forEach((item) => {
      const { itemId, sensorProfileId } = item;
      epcToCreatedItem[item.vid] = {
        itemId,
        sensorProfileId
      };
    });
  }

  const payload = itemTypeReports.reduce((acc, itemTypeReport) => {
    const { identifier: itemTypeIdentifer, epcToItemMap } = itemTypeReport;

    Object.entries(epcToItemMap).forEach(([epc, item]) => {
      if (!epcToCreatedItem[epc]) {
        acc.push({
          item_number: itemTypeIdentifer,
          item_identifier: item.identifier,
          tracker_serial: epc,
          class: itemClass
        });
      }
    });

    return acc;
  }, []);

  if (payload.length) {
    const createdItemVids = (await inventoryClient.createItemSet(payload)).createItemSet;
    const createdItems = await itemClient.getItemsBySensorProfileVids(createdItemVids);

    createdItems.forEach((item) => {
      const { itemId, sensorProfileId } = item;
      epcToCreatedItem[item.vid] = {
        itemId,
        sensorProfileId
      };
    });
  }

  return formatItemTypeReports(itemTypeReports, epcToCreatedItem);
};

const formatItemTypeReports = (itemTypeReports, epcToCreatedItem) => {
  return itemTypeReports.reduce((acc, itemTypeReport) => {
    let { id: itemTypeId } = itemTypeReport;
    const { epcToItemMap } = itemTypeReport;
    const epcs = Object.keys(epcToItemMap);

    const items = epcs.map((epc) => {
      return epcToCreatedItem[epc];
    });

    // If a new type was created through createItemSet, use that type id
    if (!itemTypeId && items.length) {
      itemTypeId = items[0].typeId;
    }

    acc.push({
      itemTypeId,
      items
    });

    return acc;
  }, []);
};
