import { ACTION_OPTIONS_MAP, QUERY_PAGE } from "../../data/constants";
import onboardConsumedItems from "../onboard-consumed-items";

export const executeActions = async (xemelgoClient, payloadsMap, locationId, customSubmitActionMap) => {
  const publishClient = xemelgoClient.getPublishClient();
  const inventoryClient = xemelgoClient.getInventoryClient();
  const promises = [];

  const executeMoveAction = async (pagedPayloads) => {
    return publishClient.userEvent(pagedPayloads, locationId);
  };

  const executeCheckOutAction = async (pagedPayloads) => {
    return publishClient.userEvent(pagedPayloads, locationId, {
      actions: {
        flipHasExitState: true
      }
    });
  };

  const executeAddAction = async (pagedPayloads) => {
    return inventoryClient.createItemSet(pagedPayloads, locationId);
  };

  const executeUnconsumeAction = async (pagedPayloads) => {
    return onboardConsumedItems(xemelgoClient, pagedPayloads, locationId);
  };

  const executeConsumeAction = async (pagedPayloads) => {
    const { endTrackingSession, consumeItem, flipHasExitState } = customSubmitActionMap || {};
    const actions = {};
    if (endTrackingSession) {
      actions.endTrackingSession = true;
    }
    if (consumeItem) {
      actions.consumeItem = true;
    }
    if (flipHasExitState) {
      actions.flipHasExitState = true;
    }

    return publishClient.userEvent(pagedPayloads, locationId, { actions });
  };

  Object.keys(payloadsMap).forEach((action) => {
    const payloads = payloadsMap[action];
    while (payloads.length) {
      const pagedPayloads = payloads.splice(0, QUERY_PAGE);
      switch (action) {
        case ACTION_OPTIONS_MAP.MOVE:
          promises.push(executeMoveAction(pagedPayloads));
          break;
        case ACTION_OPTIONS_MAP.CHECK_OUT:
          promises.push(executeCheckOutAction(pagedPayloads));
          break;
        case ACTION_OPTIONS_MAP.ADD:
          promises.push(executeAddAction(pagedPayloads));
          break;
        case ACTION_OPTIONS_MAP.UNCONSUME:
          promises.push(executeUnconsumeAction(pagedPayloads));
          break;
        case ACTION_OPTIONS_MAP.CONSUME:
          promises.push(executeConsumeAction(pagedPayloads));
          break;
        default:
          break;
      }
    }
  });
  await Promise.all(promises);
};
