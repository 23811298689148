import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Style from "./SubmissionConfirmationModal.module.css";
import Modal from "../../../../../../../../../../../../components/modal";
import useKioskConfigContext from "../../../../../../../../../../contexts/kiosk-config-context";
import useTransferOrderConfigContext from "../../../../../../contexts/transfer-order-config-context";
import useKioskStateContext from "../../../../../../../../../../contexts/kiosk-state-context";
import { STATUS_OPTIONS } from "../../../../../../../../../../../../components/status-popup-component";

export const SubmissionConfirmationModal = ({ onCancel, onConfirm, containerIdentifier, numItems }) => {
  const { action } = useKioskConfigContext();
  const { customText } = useTransferOrderConfigContext();
  const { panelValues, itemTypeReports, submitStatus } = useKioskStateContext();

  const itemTypeSummary = useMemo(() => {
    return itemTypeReports.sort((a, b) => {
      return a.identifier.localeCompare(b.identifier);
    });
  }, [itemTypeReports]);

  return (
    <Modal
      title={action.label}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmDisabled={submitStatus === STATUS_OPTIONS.LOADING}
    >
      <div className={Style.top_section}>
        <div className={Style.top_section_item}>
          <p className={Style.header_text}>{customText.transferOrderIdentifierLabel}</p>
          {panelValues.transferOrderIdentifiersString}
        </div>
        {containerIdentifier && (
          <div className={Style.top_section_item}>
            <p className={Style.header_text}>{customText.containerIdentifierLabel}</p>
            {containerIdentifier}
          </div>
        )}
      </div>
      <div className={Style.summary_container}>
        <p className={Style.header_text}>{`Items Scanned (${numItems})`}</p>
        {itemTypeSummary.map((transferOrder) => {
          const { identifier, quantityScanned } = transferOrder;
          return (
            <div
              key={identifier}
              className={Style.summary_table_row}
            >
              <p className={Style.main_row_text}>{identifier}</p>
              <p className={Style.secondary_text}>
                {`Qty: `}
                <span className={Style.bold_text}>{quantityScanned}</span>
              </p>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

SubmissionConfirmationModal.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  containerIdentifier: "",
  numItems: 0
};

SubmissionConfirmationModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  containerIdentifier: PropTypes.string,
  numItems: PropTypes.number
};
