import PropTypes from "prop-types";
import generateTagsWithPrefix from "../generate-tags-with-prefix";

const getFieldValue = (fieldData) => {
  return typeof fieldData === "object" ? fieldData?.value : fieldData;
};

export const getCreateItemPayloads = (formData, palletTagPrefix) => {
  const groupedPayloads = {};
  formData.forEach((eachForm) => {
    const { transfer_order: transferOrder, item_number: itemNumber, item_quantity: itemQuantity, location } = eachForm;

    const itemNumberValue = getFieldValue(itemNumber);
    const transferOrderValue = getFieldValue(transferOrder);
    const { itemEntriesByItemTypes } = transferOrder;

    if (!groupedPayloads[transferOrderValue]) {
      groupedPayloads[transferOrderValue] = {};
    }

    if (!groupedPayloads[transferOrderValue][itemNumberValue]) {
      groupedPayloads[transferOrderValue][itemNumberValue] = {
        payloads: [],
        itemQuantity: 0,
        locationId: location.id,
        itemNumber,
        transferOrder,
        itemEntriesCount: itemEntriesByItemTypes[itemNumberValue]?.quantityTotal || 0
      };
    }
    groupedPayloads[transferOrderValue][itemNumberValue].itemQuantity += itemQuantity;
    const vids = generateTagsWithPrefix(itemQuantity, palletTagPrefix);
    vids.forEach((vid) => {
      groupedPayloads[transferOrderValue][itemNumberValue].itemEntriesCount += 1;
      const { itemEntriesCount } = groupedPayloads[transferOrderValue][itemNumberValue];
      groupedPayloads[transferOrderValue][itemNumberValue].payloads.push({
        item_number: itemNumberValue,
        tracker_serial: vid,
        name: `${transferOrderValue}-Pallet-${itemEntriesCount}`
      });
    });
  });

  return groupedPayloads;
};

const FormDataType = PropTypes.shape({
  transfer_order: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    itemEntriesByItemTypes: PropTypes.objectOf(
      PropTypes.shape({
        quantityTotal: PropTypes.number.isRequired
      })
    ).isRequired
  }).isRequired,
  item_number: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  item_quantity: PropTypes.number,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
}).isRequired;

getCreateItemPayloads.PropTypes = {
  formData: PropTypes.arrayOf(FormDataType).isRequired,
  palletTagPrefix: PropTypes.string.isRequired
};
