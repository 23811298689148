import { XemelgoService } from "../../../../../../../../../../../../services/XemelgoService";
import getTransferOrderUpdates from "../get-transfer-order-updates";

export const publishMultiTransferOrderDetectorEvents = async (detectorId, transferOrders, itemTypeReports, stage) => {
  const publishClient = XemelgoService.getClient().getPublishClient();

  const transferOrderEvents = getTransferOrderUpdates(transferOrders, itemTypeReports, stage);

  for (const transferOrderEvent of transferOrderEvents) {
    const { id, itemTypeEvents } = transferOrderEvent;

    for (const itemTypeEvent of itemTypeEvents) {
      const { sensorProfileIds, itemsEntryId } = itemTypeEvent;

      const context = {
        actions: {
          updateTransferOrder: {
            transfer_order_id: id,
            transfer_item_entry_id: itemsEntryId,
            transfer_status: stage.submitStatus
          }
        }
      };

      await publishClient.publishEvent(detectorId, sensorProfileIds, context);
    }
  }
};
