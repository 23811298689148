import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Style from "./PrinterSelectionComponent.module.css";
import xemelgoStyle from "../../styles/variable";
import { LocalCacheService } from "../../services/local-cache-service";
import generateTemplatePrinterMaps from "./utils/generate-template-printer-maps";
import { LabelSelection } from "./components/label-selection/LabelSelection";
import PrinterSelection from "./components/printer-selection";
import PrintTimeSelection from "./components/print-time-selection";
import { PRINT_TIMES } from "../../data/constants";
import PrintQuantitySelection from "./components/print-quantity-selection";

export const PrinterSelectionComponent = ({
  printService,
  solutionType,
  hideLabelSelection,
  isPrintLaterEnabled,
  isPrintQuantityEnabled,
  showTitle
}) => {
  const {
    printers,
    templatesList,
    onSelectTemplate,
    selectedPrinterInfo,
    printerErrorMessage,
    onSelectPrinter,
    printTime,
    onPrintTimeSelect,
    defaultPrintQuantity,
    onDefaultPrintQuantityChange,
    isLoading: isPrintServiceLoading
  } = printService;
  const [printersToTemplateMap, setPrintersToTemplateMap] = useState({});
  const [templateToPrinterMap, setTemplateToPrinterMap] = useState({});
  const [selectedLabel, setSelectedLabel] = useState({});
  const [selectedPrinter, setSelectedPrinter] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleOnSelectPrinter = async (newPrinter) => {
    setIsLoading(true);
    setSelectedPrinter(newPrinter);
    await onSelectPrinter(newPrinter);
    LocalCacheService.savePrintOptions(solutionType, null, newPrinter);
    setIsLoading(false);
  };

  const handleOnSelectTemplate = async (newTemplate) => {
    const availablePrinters = templateToPrinterMap[newTemplate.id]?.printers || [];

    // auto-select printer when only one option available
    if (!Object.keys(selectedPrinter).length && Object.keys(newTemplate).length && availablePrinters.length === 1) {
      await handleOnSelectPrinter(availablePrinters[0] || {});
    }
    onSelectTemplate(newTemplate);
    setSelectedLabel(newTemplate);
    LocalCacheService.savePrintOptions(solutionType, newTemplate, null);
  };

  const onLoad = async () => {
    const printersMap = printers.reduce((newPrintersMap, printer) => {
      const { id } = printer;
      return { ...newPrintersMap, [id]: { ...printer } };
    }, {});

    // get template to printers map and printer to templates map to display in the UI
    const { printersToTemplateMap: newPrintersToTemplateMap, templateToPrinterMap: newTemplateToPrinterMap } =
      generateTemplatePrinterMaps(templatesList, printersMap);

    // get cached print options and set them as default options
    const { cachedPrinter, cachedTemplate } = LocalCacheService.getPrintOptions(solutionType) || {};
    if (cachedTemplate && Object.keys(cachedTemplate).length) {
      await handleOnSelectTemplate(cachedTemplate);
    } else if (templatesList.length === 1) {
      await handleOnSelectTemplate(templatesList[0]);
    }

    if (cachedPrinter && Object.keys(cachedPrinter).length) {
      await handleOnSelectPrinter(cachedPrinter);
    }

    setPrintersToTemplateMap(newPrintersToTemplateMap);
    setTemplateToPrinterMap(newTemplateToPrinterMap);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (isPrintServiceLoading) {
      return;
    }
    onLoad();
  }, [printers, templatesList, isPrintServiceLoading]);

  return (
    <div className={Style.print_options_container}>
      {showTitle && <p className={`${Style.header} ${xemelgoStyle.header}`}>Printer Options</p>}
      <div className={Style.print_option_body_container}>
        {isPrintLaterEnabled && (
          <PrintTimeSelection
            selectedPrintTime={printTime}
            onPrintTimeSelect={onPrintTimeSelect}
          />
        )}
        {printTime === PRINT_TIMES.now.id && (
          <>
            {!hideLabelSelection && (
              <LabelSelection
                isLoading={isLoading}
                templatesList={
                  Object.keys(selectedPrinter).length && Object.keys(printersToTemplateMap).length
                    ? printersToTemplateMap[selectedPrinter.id]?.templates || []
                    : templatesList
                }
                handleOnSelectTemplate={handleOnSelectTemplate}
                selectedLabel={selectedLabel}
              />
            )}
            {isPrintQuantityEnabled && (
              <PrintQuantitySelection
                isLoading={isLoading}
                printQuantity={defaultPrintQuantity}
                onPrintQuantityChange={onDefaultPrintQuantityChange}
              />
            )}
            <PrinterSelection
              isLoading={isLoading}
              printersList={
                Object.keys(selectedLabel).length && Object.keys(templateToPrinterMap).length
                  ? templateToPrinterMap[selectedLabel.id]?.printers || []
                  : printers
              }
              handleOnSelectPrinter={handleOnSelectPrinter}
              selectedPrinter={selectedPrinter}
              printerInfo={selectedPrinterInfo}
              errorMessage={printerErrorMessage}
            />
          </>
        )}
      </div>
    </div>
  );
};

const PrinterType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  printerObject: PropTypes.shape({})
});

const SelectedPrinterInfoType = PropTypes.shape({
  name: PropTypes.shape({
    hiddenOnCard: PropTypes.bool,
    label: PropTypes.string
  }),
  id: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),

  message: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
});

const PrintTemplateObjectType = PropTypes.shape({});

const PrintServiceType = PropTypes.shape({
  printers: PropTypes.arrayOf(PrinterType),
  selectedPrinterInfo: SelectedPrinterInfoType,
  onSelectPrinter: PropTypes.func,
  isLoading: PropTypes.bool,
  printerErrorMessage: PropTypes.string,
  templatesList: PropTypes.arrayOf(PrintTemplateObjectType),
  onPrintTemplateSelect: PropTypes.func,
  onSelectTemplate: PropTypes.func,
  printTime: PropTypes.oneOf(Object.values(PRINT_TIMES)),
  onPrintTimeSelect: PropTypes.func,
  defaultPrintQuantity: PropTypes.number,
  onDefaultPrintQuantityChange: PropTypes.func
});

PrinterSelectionComponent.propTypes = {
  printService: PrintServiceType.isRequired,
  solutionType: PropTypes.string.isRequired,
  hideLabelSelection: PropTypes.bool,
  isPrintLaterEnabled: PropTypes.bool,
  isPrintQuantityEnabled: PropTypes.bool,
  showTitle: PropTypes.bool
};

PrinterSelectionComponent.defaultProps = {
  hideLabelSelection: false,
  isPrintLaterEnabled: false,
  isPrintQuantityEnabled: false,
  showTitle: true
};
