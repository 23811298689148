import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "mdbreact";
import moment from "moment";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import { withRouter } from "react-router-dom";
import { useStore } from "react-hookstore";
import LoadingCircle from "../../components/loading/LoadingCircle";
import SummaryTileComponent from "../../components/summary-tiles-holder/SummaryTileComponent";
import SummaryTilesHolder from "../../components/summary-tiles-holder/SummaryTilesHolder";
import TrackPageComponent from "../../components/TrackPageComponents/track-page-component/TrackPageComponentV2";
import CheckBoxListComponent from "../../components/checkbox-list-component/CheckBoxListComponent";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { useXemelgoClient } from "../../services/xemelgo-service";
import Style from "./PurchaseOrderTrackPageFeature.module.css";
import { PurchaseOrderDetailModalFeature } from "../purchase-order-detail-feature/PurchaseOrderDetailModalFeature";
import { formatCurrency, formatNumber, getItemFromS3 } from "../../common/Utilities";
import { configStore } from "../../state-managements/stores/config-store";
import { ReactComponent as PurchaseOrderIcon } from "../../assets/icons/purchase-order.svg";
import xemelgoStyle from "../../styles/variable";

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const APP_ID = "purchaseOrder";
const TRACK_PAGE_CONFIG = "trackPage";
const SIDE_TABS = { purchase_order: "Purchase Orders" };
const FILTER_TABS = { vendor: "By Vendor" };
const mainColor = xemelgoStyle.theme.PURCHASE_PRIMARY;
const secondaryColor = xemelgoStyle.theme.PURCHASE_SECONDARY;
const DEFAULT_NUM_VENDORS = 10;
const defaultNumExpandedHeaders = 1;

const PurchaseOrderTrackPageFeature = ({ history }) => {
  const isInitialMount = useRef(true);
  const statusTileState = useRef({
    locationSummaryTileMap: {
      Open: true,
      Closed: false,
      Cancelled: false
    },
    filterViewEnabled: true
  });

  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, "object"); // TODO
  const [customerConfiguration] = useStore(configStore);
  const [purchaseOrderContentMetadataList, setPurchaseOrderContentMetadataList] = useState(null);

  // Progress / Loading Indicators
  const [pageLoading, setPageLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(true);

  // Config-Driven UI Settings
  const [currentSideTab, setCurrentSideTab] = useState(Object.keys(SIDE_TABS)[0]);
  const [pageTitle, setPageTitle] = useState("");

  // UI Element States
  const [displayedVendorStartIndex, setDisplayedVendorStartIndex] = useState(0);
  const [isContentSeeMoreNeeded, setIsContentSeeMoreNeeded] = useState(false);

  const [orderStatusMetadata, setOrderStatusMetadata] = useState([]);
  const [fulfillmentStatusMetadata, setFulfillmentStatusMetadata] = useState([]);
  const [statusTileData, setStatusTileData] = useState({});

  const [kpiTileMetadata, setKpiTileMetadata] = useState({});
  const [kpiTileData, setKpiTileData] = useState({});

  const [vendorContentMetadataList, setVendorContentMetadataList] = useState([]); // holds (currently-displayed) Vendor header data
  const [vendorContentList, setVendorContentList] = useState([]); // holds PO's in objects keyed by vendorID's

  const [totalNumVendors, setTotalNumVendors] = useState(-1);
  // init name list null to distinguish (unfetched) and (fetched but 0 vendors)
  const [vendorNameList, setVendorNameList] = useState(null);
  const [vendorMetricsData, setVendorMetricsData] = useState({}); // holds Vendor metrics keyed by vendorID

  // Following are used to populate filter panel:
  const [masterBuyerNameList, setMasterBuyerNameList] = useState(null);
  const [masterVendorNameList, setMasterVendorNameList] = useState(null);
  const [masterItemTypeList, setMasterItemTypeList] = useState(null);

  // Following contain active Filter Panel selections
  const [filterPanelVendors, setFilterPanelVendors] = useState([]);
  const [filterPanelBuyers, setFilterPanelBuyers] = useState([]);
  const [filterPanelItemTypes, setFilterPanelItemTypes] = useState([]);
  const [filterPanelDateMin, setFilterPanelDateMin] = useState(0);
  const [filterPanelStatuses, setFilterPanelStatuses] = useState([]);

  const [purchaseOrderClient] = useState(useXemelgoClient().getPurchaseOrderClient());

  // API Response Cache
  const [purchaseOrderId, setPurchaseOrderId] = useState(undefined);
  const [aggregateQueryState, setAggregateQueryState] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [filterInputType, setFilterInputType] = useState(null);
  const [paginators, setPaginators] = useState([]);
  const [sorters, setSorters] = useState([]);
  const [expansions, setExpansions] = useState([]);

  const onLoad = async () => {
    // Fetch default UI options from Config
    const { defaultViewMode, orderStatusMetadata, fulfillmentStatusMetadata, kpiMetadataList } = TrackPageConfig;

    const { purchaseOrder } = customerConfiguration.config.solutionConfiguration;
    setPageTitle(purchaseOrder?.title);
    setPurchaseOrderContentMetadataList(purchaseOrder?.purchaseOrderContentColumnMetadataList);

    // Set UI View Tab defaults based on Config:
    if (Object.keys(SIDE_TABS).includes(defaultViewMode)) {
      // only use config value if is valid!
      setCurrentSideTab(defaultViewMode);
    }

    // Store metadata for status fetched from config
    setOrderStatusMetadata(orderStatusMetadata);
    setFulfillmentStatusMetadata(fulfillmentStatusMetadata);
    setKpiTileMetadata(kpiMetadataList);

    // Done setting UI defaults; can hide page-level loading
  };

  const getBuyerDisplayStringFromObject = (person) => {
    return (`${person?.given_name} ${person?.family_name}`.trim() || person?.identifier || "").trim();
  };

  /**
   * Callback to update state vars associated with Text Filter, which is rendered by a
   * child component. This approach supports business logic residing in the Feature.
   * @param {String} newFilterInput Currently-displayed text filter bar input
   * @param {String} newFilterInputType Current text filter dropdown selection
   */
  const updateFilterStateCallback = (newFilterInput, newFilterInputType) => {
    if (newFilterInput !== filterInput) {
      setFilterInput(newFilterInput);
    }
    if (newFilterInputType?.length && newFilterInputType !== filterInputType) {
      setFilterInputType(newFilterInputType);
    }
  };

  const generateUpdateExpansionStateCallback = (vendorIdentifier, newIsExpanded = true) => {
    const expansion = lookupExpansion(vendorIdentifier) || {};
    expansion[vendorIdentifier] = { expanded: newIsExpanded };
    expansion.time = Date.now();
    setExpansions((prevExpansions) => {
      return [...prevExpansions, expansion];
    });
    if (newIsExpanded) {
      let sorter = lookupSorter(vendorIdentifier) || {};
      let paginator = lookupPaginator(vendorIdentifier) || {};
      if (!Object.keys(paginator)?.length) {
        paginator = resetPaginator(vendorIdentifier);
      }
      if (!Object.keys(sorter)?.length) {
        sorter = resetSorter(vendorIdentifier);
      }

      getPurchaseOrdersForVendor(
        vendorIdentifier,
        paginator,
        sorter,
        filterPanelBuyers,
        filterPanelItemTypes,
        filterPanelStatuses.map((filterPanelStatusObject) => {
          return filterPanelStatusObject?.id;
        }),
        false
      );
    } else {
      resetPaginator(vendorIdentifier);
      resetSorter(vendorIdentifier);
    }
  };

  /**
   * Generator for different pagination callbacks. We use a generator because
   * each content item may have its own Paginator whose state must be distinct.
   * Content Paginator state vars must be tracked by the feature in order to
   * keep pagination-related business logic out of child components.
   *
   * @param {String} vendorIdentifier Identifier of Vendor (Partner) to generate
   * a pagination callback for.
   * @returns A function to be passed to the ListView of `vendorIdentifier` that
   * accepts the state vars associated with the ListView's Paginator and performs
   * the business logic, namely fetching the new data to be displayed.
   */
  const generateUpdatePaginationStateCallback = (vendorIdentifier) => {
    return (newStart, newCount) => {
      const currentAffectState = getPaginationAffectedByState(vendorIdentifier, true, false);
      const paginatorLookup = lookupPaginator(vendorIdentifier);
      const match = paginatorLookup?.[vendorIdentifier];
      if (isNaN(parseInt(newStart)) || newStart < 0) {
        newStart = match?.start || 0;
      }

      if (isNaN(parseInt(newCount)) || !(newCount > 0)) {
        newCount = match?.count || 10;
      }

      const item = {};
      item[vendorIdentifier] = { start: newStart, count: newCount };
      item.time = Date.now();
      item.state = currentAffectState;
      // storing Date.now() seems a tad hacky but enables robustness with respect to
      // how React groups execution of state updates when we are using a list
      // to store (a dynamic number of) independent states.
      setPaginators((prev) => {
        return [...prev, item];
      });

      // current implementation of Pagination relies on entire list being present when changing pages;
      // e.g. after moving from page 1 -> 2, it expects 2 pages' worth of rows present. Must tell query
      // response parser to merge the newly-fetched data with existing
      let shouldMergeData = false;
      if (
        paginatorLookup?.state === currentAffectState &&
        paginatorLookup?.[vendorIdentifier]?.count === newCount &&
        filterPanelItemTypes?.length === 0 &&
        !(match?.start > newStart)
      ) {
        shouldMergeData = true;
      }

      const expansion = lookupExpansion(vendorIdentifier);
      if (expansion && expansion[vendorIdentifier]?.expanded) {
        const sorter = lookupSorter(vendorIdentifier);
        getPurchaseOrdersForVendor(
          vendorIdentifier,
          item,
          sorter,
          filterPanelBuyers,
          filterPanelItemTypes,
          filterPanelStatuses.map((filterPanelStatusObject) => {
            return filterPanelStatusObject?.id;
          }),
          shouldMergeData
        );
      }
    };
  };

  /**
   * Generator for different column sort callbacks. We use a generator because
   * each content item may have its own Column Sort impl whose state must be distinct.
   * Content Column Sort state vars must be tracked by the feature in order to
   * keep sort-related business logic out of child components.
   *
   * @param {String} vendorIdentifier Identifier of Vendor (Partner) to generate
   * a column sort callback for.
   * @returns A function to be passed to the ListView of `vendorIdentifier` that
   * accepts the state vars associated with the ListView's Column Sort and performs
   * the business logic, namely fetching the new data to be displayed.
   */
  const generateUpdateColumnSortStateCallback = (vendorIdentifier) => {
    return (newColumnKey, newDirection) => {
      const currentAffectState = getPaginationAffectedByState(vendorIdentifier, false, true);
      const match = lookupSorter(vendorIdentifier)?.[vendorIdentifier];
      if (!newColumnKey) {
        newColumnKey = match?.key || "identifier";
      }
      if (!newDirection) {
        newDirection = match?.direction || "incr";
      }

      const sorter = {};
      sorter[vendorIdentifier] = { key: newColumnKey, direction: newDirection };
      sorter.time = Date.now(); // see above about ['time'] and Date.now()
      sorter.state = currentAffectState;
      setSorters((prev) => {
        return [...prev, sorter];
      });

      const expansion = lookupExpansion(vendorIdentifier);
      if (expansion && expansion[vendorIdentifier]?.expanded) {
        const paginator = {};
        paginator[vendorIdentifier] = { start: 0, count: 10 };
        paginator.time = Date.now();
        paginator.state =
          getPaginationAffectedByState(vendorIdentifier, true, true) + JSON.stringify(sorter[vendorIdentifier]);
        setPaginators((prevPaginators) => {
          return [...prevPaginators, paginator];
        });

        if (paginator) {
          getPurchaseOrdersForVendor(
            vendorIdentifier,
            paginator,
            sorter,
            filterPanelBuyers,
            filterPanelItemTypes,
            filterPanelStatuses.map((filterPanelStatusObject) => {
              return filterPanelStatusObject?.id;
            }),
            false
          );
        }
      }
    };
  };

  const lookupPaginator = (vendorIdentifier) => {
    return doStateLookup(vendorIdentifier, paginators);
  };

  const lookupSorter = (vendorIdentifier, shouldReturnDefault = false) => {
    let x = doStateLookup(vendorIdentifier, sorters) || {};
    if (!x?.[vendorIdentifier] && shouldReturnDefault) {
      x = {};
      x[vendorIdentifier] = { key: "identifier", direction: "incr" };
    }
    return x;
  };

  const lookupSorterForListView = (vendorIdentifier) => {
    let ans = { ...lookupSorter(vendorIdentifier)?.[vendorIdentifier] };
    if (ans?.direction === "decr") {
      ans.direction = "desc";
    } else if (ans?.direction === "incr") {
      ans.direction = "asc";
    }
    if (!Object.keys(ans || {}).length) {
      ans = { direction: "asc", key: "identifier" };
    }
    return ans;
  };

  const lookupExpansion = (vendorIdentifier) => {
    return doStateLookup(vendorIdentifier, expansions);
  };

  const doStateLookup = (vendorIdentifier, stateObject) => {
    let answerIndex = -1;
    (stateObject || []).forEach((item, i) => {
      if (Object.keys(item).indexOf(vendorIdentifier) !== -1) {
        if (answerIndex === -1 || item?.time > stateObject[answerIndex]?.time) {
          answerIndex = i;
        }
      }
    });
    return answerIndex === -1 ? null : stateObject[answerIndex];
  };

  const recomputeQueryString = () => {
    let summaryTileString = "";
    Object.keys(statusTileState.current?.locationSummaryTileMap).forEach((key) => {
      summaryTileString += String(statusTileState.current.locationSummaryTileMap[key]);
    });
    const ans = String(filterInputType) + String(filterInput) + summaryTileString + String(displayedVendorStartIndex);
    if (ans !== aggregateQueryState) {
      setAggregateQueryState(ans);
    }
  };

  const getFilterPanelSelectionString = () => {
    return (
      String(filterPanelDateMin) +
      JSON.stringify(filterPanelVendors) +
      JSON.stringify(filterPanelItemTypes) +
      JSON.stringify(filterPanelBuyers) +
      JSON.stringify(filterPanelStatuses)
    );
  };

  const resetPaginator = (vendorIdentifier) => {
    const paginator = {};
    paginator[vendorIdentifier] = { start: 0, count: 10 };
    paginator.time = Date.now();
    paginator.state = getPaginationAffectedByState(vendorIdentifier, true, false);
    setPaginators((prevPaginators) => {
      return [...prevPaginators, paginator];
    });
    return paginator;
  };

  const resetSorter = (vendorIdentifier) => {
    const sorter = {};
    sorter[vendorIdentifier] = { key: "identifier", direction: "incr" };
    sorter.time = Date.now();
    setSorters((prev) => {
      return [...prev, sorter];
    });
    return sorter;
  };

  const getPaginationAffectedByState = (vendorIdentifier, ignorePagination = false, ignoreSort = false) => {
    let summaryTileString = "";
    Object.keys(statusTileState.current?.locationSummaryTileMap).forEach((key) => {
      summaryTileString += String(statusTileState.current.locationSummaryTileMap[key]);
    });
    return (
      String(filterInputType) +
      String(filterInput) +
      summaryTileString +
      String(displayedVendorStartIndex) +
      getFilterPanelSelectionString() +
      (!ignoreSort ? JSON.stringify(lookupSorter(vendorIdentifier, true)?.[vendorIdentifier]) : "") +
      (!ignorePagination ? JSON.stringify(lookupPaginator(vendorIdentifier)?.[vendorIdentifier]) : "")
    );
  };

  useEffect(() => {
    recomputeQueryString();
  }, [filterInput, filterInputType, statusTileState, displayedVendorStartIndex, expansions, sorters]);

  useEffect(() => {
    const vendorListToUse = (filterPanelVendors?.length ? filterPanelVendors : vendorNameList) || [];
    vendorListToUse.forEach((someVendor) => {
      resetPaginator(someVendor?.identifier);
    });
  }, [filterInput, filterInputType]);

  useEffect(() => {
    if (!isInitialMount.current) {
      // isInitialMount gets set true in numVendors Hook which must execute first
      let vendorListToUse = vendorNameList;
      if ((filterPanelVendors || []).length) {
        vendorListToUse = filterPanelVendors;
      }
      loadContent(0, vendorListToUse);
      loadStatusTileContent();
    }
  }, [filterPanelBuyers, filterPanelVendors, filterPanelDateMin, filterPanelStatuses]);

  useEffect(() => {
    const vendorListToUse = filterPanelVendors?.length ? filterPanelVendors : vendorNameList;
    (vendorListToUse || []).forEach((vendor, i) => {
      if (i < displayedVendorStartIndex + DEFAULT_NUM_VENDORS) {
        const vid = vendor?.identifier;
        const expansion = lookupExpansion(vid);
        if (expansion && expansion[vid]?.expanded) {
          getPurchaseOrdersForVendor(
            vid,
            lookupPaginator(vid),
            lookupSorter(vid),
            filterPanelBuyers,
            filterPanelItemTypes,
            filterPanelStatuses.map((filterPanelStatusObject) => {
              return filterPanelStatusObject?.id;
            }),
            false
          );
        }
      }
    });
  }, [aggregateQueryState]);

  useEffect(() => {
    if (Object.keys(vendorMetricsData)?.length) {
      recomputeQueryString();
    }
  }, [vendorMetricsData]);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    // fetch & save lists of filter options s.t. filter panel can be populated
    purchaseOrderClient.getPartnerNames().then((res) => {
      setMasterVendorNameList(res.partners);
    });
    purchaseOrderClient.getBuyerNames().then((res) => {
      setMasterBuyerNameList(res.persons);
    });
    purchaseOrderClient.getItemTypes().then((res) => {
      setMasterItemTypeList(res.itemTypes);
    });
  }, [customerConfiguration]);

  useEffect(() => {
    if (
      Object.keys(kpiTileMetadata || []).length &&
      Object.keys(orderStatusMetadata || []).length &&
      Object.keys(fulfillmentStatusMetadata || []).length
    ) {
      setPageLoading(false);
      loadStatusTileContent();
    }
  }, [kpiTileMetadata, orderStatusMetadata, fulfillmentStatusMetadata]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const getTotalNumVendors = async () => {
        Promise.resolve(purchaseOrderClient.getPartnerNames({ countOnly: true })).then((res) => {
          const total = res.partners?.value || 0;
          if (total > DEFAULT_NUM_VENDORS) {
            setIsContentSeeMoreNeeded(true);
          }
          setTotalNumVendors(total);
          return total;
        });
      };
      getTotalNumVendors();
    } else {
      loadContent(displayedVendorStartIndex);
    }
  }, [displayedVendorStartIndex]);

  useEffect(() => {
    if (totalNumVendors !== -1) {
      loadContent(displayedVendorStartIndex);
    }
  }, [totalNumVendors]);

  const onClickContentSeeMore = () => {
    // if there aren't more names hidden, hide See More
    if (totalNumVendors !== -1 && displayedVendorStartIndex + DEFAULT_NUM_VENDORS >= totalNumVendors) {
      setIsContentSeeMoreNeeded(false);
    }
    setDisplayedVendorStartIndex(displayedVendorStartIndex + DEFAULT_NUM_VENDORS);
  };

  const loadStatusTileContent = async () => {
    setStatusTileData({});

    const response = await purchaseOrderClient.getPurchaseOrdersOverallMetricsByStatus(
      filterPanelVendors.map((x) => {
        return x.identifier;
      }),
      filterPanelBuyers.map((x) => {
        return x.identifier;
      }),
      filterPanelItemTypes.map((x) => {
        return x.identifier;
      }),
      filterPanelStatuses.map((x) => {
        return x.id;
      }),
      filterPanelDateMin !== 0 ? Date.now() - filterPanelDateMin : 0
    );

    // Collect responses into status tile data object
    const blank = { count: 0, cost: 0, quantity: 0 };
    const newStatusTileData = {
      Open: { ...blank },
      Cancelled: { ...blank },
      Closed: { ...blank }
    };
    response.forEach((metricsObject) => {
      const {
        order_status,
        totalPOCount = 0,
        totalOrderedCost = 0,
        totalOrderedQuantity = 0,
        totalFulfilledCost = 0,
        totalFulfilledQuantity = 0
      } = metricsObject;
      newStatusTileData[order_status] = {
        count: newStatusTileData[order_status].count + totalPOCount,
        cost: newStatusTileData[order_status].cost + totalOrderedCost,
        quantity: newStatusTileData[order_status].quantity + totalOrderedQuantity,
        totalFulfilledCost,
        totalFulfilledQuantity
      };
    });

    const statusTileStateCopy = { ...statusTileState.current };
    let totalCostYTD = 0;
    let totalQuantityYTD = 0;
    Object.keys(newStatusTileData).forEach((key) => {
      totalCostYTD +=
        key !== "Cancelled" && newStatusTileData[key].totalFulfilledCost !== undefined
          ? newStatusTileData[key].totalFulfilledCost
          : 0;
      totalQuantityYTD +=
        key !== "Cancelled" && newStatusTileData[key].totalFulfilledQuantity !== undefined
          ? newStatusTileData[key].totalFulfilledQuantity
          : 0;
    });

    // append aggregate status tiles
    const newKpiTileData = {};
    const numPlacesYtd = kpiTileMetadata?.ytd_cost?.num_decimal_places || 0;
    const numPlacesAvg = kpiTileMetadata?.avg_cost?.num_decimal_places || 0;
    newKpiTileData.ytd_cost = {
      value: Math.round(totalCostYTD * 10 ** numPlacesYtd) / 10 ** numPlacesYtd
    };
    newKpiTileData.avg_cost = {
      value:
        Math.round(10 ** numPlacesAvg * totalQuantityYTD > 0 ? totalCostYTD / totalQuantityYTD : 0) / 10 ** numPlacesAvg
    };

    setKpiTileData(newKpiTileData);

    for (let i = 0; i < Object.keys(newStatusTileData)?.length; i++) {
      if (
        newStatusTileData[Object.keys(newStatusTileData)[i]]?.id &&
        !(newStatusTileData[Object.keys(newStatusTileData)[i]]?.id in statusTileStateCopy.locationSummaryTileMap)
      ) {
        statusTileStateCopy.locationSummaryTileMap[newStatusTileData[Object.keys(newStatusTileData)[i]]?.id] = true;
      }
    }
    setStatusTileData(newStatusTileData);
    statusTileState.current = { ...statusTileStateCopy };
  };

  const getEnabledStatusTileSetList = () => {
    const tileNames = Object.keys(statusTileState.current.locationSummaryTileMap).slice(0, 4);
    const enabledTiles = tileNames.filter((tile) => {
      return statusTileState.current.locationSummaryTileMap[tile];
    });
    return enabledTiles;
  };

  const getPurchaseOrdersForVendor = async (
    partnerIdentifier,
    paginator,
    sorter,
    buyersToFilter = [],
    itemTypesToFilter = [],
    fulfillmentStatusesToFilter = [],
    shouldMerge = false
  ) => {
    let item = {};
    item[partnerIdentifier] = "LOADING";
    item.time = Date.now();
    setVendorContentList((prevVendorContentList) => {
      return [...prevVendorContentList, item];
    });

    paginator = paginator && paginator[partnerIdentifier];
    sorter = sorter && sorter[partnerIdentifier];

    let pos = [];
    let partner = {};

    const execute = async () => {
      const buyerIdentifiers = (buyersToFilter || []).map((buyerObject) => {
        return buyerObject.identifier;
      });

      const itemTypeIdentifiers = itemTypesToFilter.map((itemTypeObject) => {
        return itemTypeObject.identifier;
      });

      // If paging forward, request new data and merge
      // If paging backwards, check Affect state and slice the list of previously-fetched POs
      const lookup = doStateLookup(partnerIdentifier, vendorContentList);
      const previousPOsForVendor = lookup?.[partnerIdentifier];
      if (!itemTypeIdentifiers?.length && (lookup || {})?.state === aggregateQueryState) {
        if (
          (previousPOsForVendor || []).length > (paginator?.start || 0) + (paginator?.count || 10) &&
          previousPOsForVendor[0] !== "LOADING"
        ) {
          const B = lookupSorter(partnerIdentifier, true)?.[partnerIdentifier];
          if (sorter?.key === B?.key && sorter?.direction === B?.direction) {
            // TODO Det if direction
            const totalCount = paginator?.start + paginator?.count;
            item = {};
            const copy = [...previousPOsForVendor];
            pos = copy.slice(0, totalCount);
            return;
          }
        }
      }

      const response = await purchaseOrderClient.getPurchaseOrdersGivenVendorPaginationSortAndFilters(
        [partnerIdentifier],
        itemTypeIdentifiers?.length ? -1 : paginator?.start || 0,
        itemTypeIdentifiers?.length ? -1 : paginator?.count || 10,
        sorter?.key || "identifier",
        sorter?.direction || "incr",
        filterInput,
        filterInputType,
        getEnabledStatusTileSetList(),
        fulfillmentStatusesToFilter,
        buyerIdentifiers,
        itemTypeIdentifiers,
        filterPanelDateMin !== 0 ? Date.now() - filterPanelDateMin : 0
      );

      const partners = response?.partners;
      partner = partners?.length > 0 ? partners[0] : null;
      pos = partner?.associatedWithPurchaseOrder || [];
    };
    await execute();

    if (pos.length === 0 && paginator?.start > 0) {
      // Found 0 PO's starting at page P > 1; try again starting at 0
      paginator.start = 0;
      const paginatorFn = generateUpdatePaginationStateCallback(partnerIdentifier);
      paginatorFn(0, paginator?.count);
    }

    pos.forEach((po) => {
      // use fulfillment_status, order_status to populate user-facing Status field
      po.display_status = po.order_status;
      po.vendor = partner?.name || partner.identifier;
      po.buyer = "-";

      // use issuedBy connection to populate Buyer field
      if (po.issuedBy && po.issuedBy?.length > 0) {
        const person = po.issuedBy[0];
        po.buyer = getBuyerDisplayStringFromObject(person) || "-";
      }
    });

    item = {};
    item[partnerIdentifier] = pos;
    item.time = Date.now();
    item.state = aggregateQueryState;
    if (shouldMerge) {
      const lookup = doStateLookup(partnerIdentifier, vendorContentList)?.[partnerIdentifier];
      item[partnerIdentifier] = lookup.concat(pos);
    }
    setVendorContentList((prevVendorContentList) => {
      return [...prevVendorContentList, item];
    });
  };

  const getLastVisitedContentPage = (vendorIdentifier) => {
    const lookup = lookupPaginator(vendorIdentifier)?.[vendorIdentifier];
    return lookup?.count > 0 ? lookup?.start / lookup.count : 0;
  };

  const getNumPOsGivenVendorStatusAndTextFilter = async (vendorIdentifier) => {
    const res = await purchaseOrderClient.getPurchaseOrdersHeaderMetricsByVendor(
      [vendorIdentifier],
      filterPanelBuyers.map((x) => {
        return x.identifier;
      }),
      filterPanelItemTypes.map((x) => {
        return x.identifier;
      }),
      filterPanelStatuses.map((x) => {
        return x.id;
      }),
      filterPanelDateMin !== 0 ? Date.now() - filterPanelDateMin : 0,
      filterInput,
      filterInputType
    );
    return res;
  };

  /**
   * For a given Vendor, determines the # of PO's that meet the
   * current Status Tile filter criteria. This function is called by
   * each Vendor's ListView to get the correct total for pagination.
   * @param {*} vendorIdentifier
   * @returns The total number of PO's associated with `vendorIdentifier`
   * that would eventually be displayed in the ListView (e.g. if paging
   * all the way).
   */
  const getNumPOsGivenVendorAndStatusTileFilter = (vendorIdentifier) => {
    const statusMap = statusTileState.current?.locationSummaryTileMap;
    const lookup = vendorMetricsData[vendorIdentifier];

    // If text filter is not empty, take into account along with Status Filter
    // when determining length for paginators
    if (filterInput?.length) {
      let contentIndex = -1;
      let contLen = 0;
      const vendorContentListCopy = (vendorContentList || []).reverse();
      vendorContentListCopy.forEach((vendorContent, i) => {
        Object.keys(vendorContent).forEach((key) => {
          if (
            key === vendorIdentifier &&
            (contentIndex === -1 || vendorContent.time > vendorContentListCopy[contentIndex].time)
          ) {
            contentIndex = i;
            if (vendorContent[key] === "LOADING") {
              return;
            }
            contLen = (vendorContentListCopy[i][key] || []).filter((po) => {
              return statusMap[po?.order_status];
            }).length;
          }
        });
      });
      if (contentIndex !== -1) {
        return contLen;
      }
    }

    // No text filter entails just Vendor Metrics and Active Status Tiles
    // may be used to determine length for paginators
    let ans = 0;
    Object.keys(statusMap).forEach((tileName) => {
      if (statusMap?.[tileName]) {
        ans += lookup?.[tileName] || 0;
      }
    });
    return ans;
  };

  const getNumberOfRowsPerPageForPartner = (partnerIdentifier) => {
    const paginator = lookupPaginator(partnerIdentifier);
    return paginator?.[partnerIdentifier]?.count;
  };

  const fetchVendorMetricsData = async (vendorIdentifiers, shouldReturnInsteadOfSet) => {
    const vendorMetricsDataCopy = { ...vendorMetricsData };

    const execute = async () => {
      const response = await purchaseOrderClient.getPurchaseOrdersHeaderMetricsByVendor(
        vendorIdentifiers,
        filterPanelBuyers.map((x) => {
          return x.identifier;
        }),
        filterPanelItemTypes.map((x) => {
          return x.identifier;
        }),
        filterPanelStatuses.map((x) => {
          return x.id;
        }),
        filterPanelDateMin !== 0 ? Date.now() - filterPanelDateMin : 0,
        "",
        ""
      );
      const partners = response?.partners || [];
      partners.forEach((partner) => {
        // update stored metrics obj for this vendor with newly-fetched
        // metrics for this enabled status
        const current = {
          Open: 0,
          Closed: 0,
          Cancelled: 0
        };
        const purchaseOrdersGivenVendor = partner?.associatedWithPurchaseOrder;
        (purchaseOrdersGivenVendor || []).forEach((purchaseOrder) => {
          const purchaseOrderStatus = purchaseOrder.order_status;
          current[purchaseOrderStatus] = purchaseOrder?.count;
        });
        vendorMetricsDataCopy[String(partner?.identifier)] = current;
      });
    };
    await execute();
    if (shouldReturnInsteadOfSet) {
      return vendorMetricsDataCopy;
    }
    setVendorMetricsData(vendorMetricsDataCopy);
  };

  const getVendorMetricsMetadata = () => {
    return [
      { id: "Open", label: "Open", value: "..." },
      { id: "Cancelled", label: "Cancelled", value: "..." },
      { id: "Closed", label: "Closed", value: "..." }
    ];
  };

  const loadContent = async (startIndex = 0, existingPartnerList = []) => {
    setContentLoading(true);

    let newNumPartners = existingPartnerList?.length || 0;
    let partnerNameListToUse = [];
    let metadata = [];

    if (existingPartnerList?.length) {
      // disable See More if user has selected Vendor(s) to display
      setIsContentSeeMoreNeeded(false);
      partnerNameListToUse = existingPartnerList;
    } else {
      const vl = await purchaseOrderClient.getPartnerNames({
        sortDirection: "incr",
        start: startIndex,
        count: startIndex + DEFAULT_NUM_VENDORS
      });
      const { partners = [] } = vl;
      const vendorNameListCopy = startIndex > 0 && vendorNameList?.length ? [...vendorNameList] : [];
      setVendorNameList((prevList) => {
        return (prevList || []).concat(partners);
      });
      newNumPartners = partners.length + (vendorNameList || []).length;
      setIsContentSeeMoreNeeded(newNumPartners < totalNumVendors);
      metadata = vendorContentMetadataList || [];
      partnerNameListToUse = vendorNameListCopy.concat(partners || []);
    }

    // Check FilterPanel vendor selections to force expansion
    // const newExpansions = [];
    // (filterPanelVendors || []).forEach((filterPanelVendor) => {
    //   newExpansions.push(filterPanelVendor?.identifier);
    // });
    // setForcedExpandedHeaders(newExpansions);

    // add headers to metadata list and fetch metrics
    const newDataList = [];
    partnerNameListToUse.forEach((partner, i) => {
      const { id, identifier, name } = partner;
      // Skip vendor if already in renderDataList
      if (i < metadata.length && metadata[i]?.id === identifier) {
        return;
      }
      newDataList.push({
        headerTitle: name || identifier || "Unknown Name",
        id: identifier
      });
    });
    setVendorContentMetadataList(metadata.concat(newDataList));
    fetchVendorMetricsData(
      newDataList.map((data) => {
        return data.id;
      }),
      false
    );
    setContentLoading(false);
  };

  // filter view is true if there's at least one disabled tile, false if all are enabled
  const isFilterViewEnabled = (summaryTileMap) => {
    for (const v of Object.values(summaryTileMap)) {
      if (!v) {
        return true;
      }
    }
    return false;
  };

  const getViewTabStructure = () => {
    let viewTabStructure = [];
    viewTabStructure = Object.keys(FILTER_TABS).map((eachTabKey) => {
      return {
        id: eachTabKey,
        label: FILTER_TABS[eachTabKey],
        onClick: () => {}
      };
    });
    return viewTabStructure;
  };

  /**
   * Generates input to TabListComponent to display View options.
   * TabListComponent expects an array where each item corresponds
   * to a group of options!
   * TODO maintains URL ?
   */
  const getStatusViewTabStructure = () => {
    const label = "View";
    let tabStructure = [];
    tabStructure = Object.keys(SIDE_TABS).map((eachTabKey) => {
      return {
        id: eachTabKey,
        display: SIDE_TABS[eachTabKey],
        isDisabled: SIDE_TABS[eachTabKey].indexOf("Item") !== -1,
        action: () => {
          setCurrentSideTab(eachTabKey);
        }
      };
    });

    const viewTabStructure = {
      id: label,
      display: label,
      tabStructure
    };
    return [viewTabStructure];
  };

  const generateRenderComponentCurrency = () => {
    return getFormattedColumnRenderComponent(formatCurrency);
  };

  const generateRenderComponentDate = () => {
    return getFormattedColumnRenderComponent((inStr) => {
      return String(moment(inStr).format("MM/DD/YYYY"));
    });
  };

  const getFormattedColumnRenderComponent = (formatter) => {
    return (cellData) => {
      return <div>{cellData !== "-" ? formatter(cellData) : cellData}</div>;
    };
  };

  const renderContentFulfillmentStatus = (status) => {
    return renderContentStatus(status, fulfillmentStatusMetadata);
  };

  const renderContentOrderStatus = (status) => {
    return renderContentStatus(status, orderStatusMetadata);
  };

  const renderContentStatus = (status = "", metadataList) => {
    let backgroundColor;
    let displayString = "";
    metadataList.forEach((metadata) => {
      if ((metadata?.id || "").toLowerCase() === (status || "").toLowerCase()) {
        backgroundColor = metadata?.color;
        displayString = metadata?.display_name;
      }
    });
    return (
      <div
        className={Style.list_view_status}
        style={{ backgroundColor }}
      >
        {displayString}
      </div>
    );
  };

  const handleActionClick = async (action, id) => {
    switch (action) {
      case "View/Edit":
        // window.fcWidget.hide();
        setPurchaseOrderId(id);
        break;
      case "Download as PDF":
        const result = await purchaseOrderClient.generatePurchaseOrderPDF(id);
        await getItemFromS3("xemelgo-generated-reports", result);
        break;
      default:
        break;
    }
  };

  const content_action_options = ["Download as PDF", "View/Edit"];
  const renderContentActions = (action_options, id) => {
    if (!action_options) {
      action_options = content_action_options;
    }
    return (
      <Dropdown>
        <DropdownToggle
          nav
          className={Style.more_button}
        >
          <MoreIcon fontSize="small" />
        </DropdownToggle>
        <DropdownMenu
          className={Style.options_container}
          right
        >
          {action_options.map((option) => {
            return (
              <DropdownItem
                key={option}
                onClick={() => {
                  handleActionClick(option, id);
                }}
                className={Style.option_item}
              >
                <div className={Style.option_text}>{option}</div>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  };

  /**
   * TODO Combine this with getContentListViewStructure(); this function
   * currently only needed to use TextfilterDropDown out-of-box.
   * Fetches content column map from config and shapes into dropdown
   * config for use by TextFilterDropDown.
   * @returns
   */
  const getContentSortSchema = () => {
    if (purchaseOrderContentMetadataList) {
      const ans = purchaseOrderContentMetadataList.map((metadata) => {
        // 0-length keys are ignored
        if (metadata?.text_filter_enabled && metadata.key?.length > 0) {
          return {
            type: metadata?.type,
            value: metadata?.label,
            id: metadata?.key
          };
        }
        return null;
      });
      return ans.filter((a) => {
        return a !== null;
      });
    }
  };

  const getContentListViewStructure = () => {
    // TODO Load from Config and delete this func
    return [
      {
        id: "identifier",
        index: 0,
        default: true,
        label: "PO No",
        title: true,
        desc: false,
        type: "string"
      },
      {
        id: "creation_date",
        index: 1,
        label: "PO Date",
        type: "date",
        renderComponent: generateRenderComponentDate()
      },
      {
        id: "order_status",
        index: 2,
        label: "PO Status",
        renderComponent: renderContentOrderStatus
      },
      {
        id: "fulfillment_status",
        index: 3,
        label: "Received Status",
        renderComponent: renderContentFulfillmentStatus
      },
      { id: "vendor", index: 4, label: "Vendor" },
      { id: "buyer", index: 5, label: "Buyer" },
      {
        id: "total_cost_by_quantities_ordered",
        index: 6,
        label: "Estimated Cost",
        type: "numeric",
        renderComponent: generateRenderComponentCurrency()
      },
      {
        id: "total_cost_by_quantities_fulfilled",
        index: 7,
        label: "Received Cost",
        type: "numeric",
        renderComponent: generateRenderComponentCurrency()
      },
      {
        id: "scheduled_ship_date",
        index: 8,
        label: "Scheduled Ship Date",
        type: "date",
        renderComponent: generateRenderComponentDate()
      },
      {
        id: "fulfillment_date",
        index: 9,
        label: "Received Date",
        type: "date",
        renderComponent: generateRenderComponentDate()
      },
      {
        id: "comments",
        index: 10,
        label: "Remarks"
      },
      {
        id: "",
        index: 11,
        label: "Actions",
        disabled: true,
        renderComponent: renderContentActions
      }
    ];
  };

  const handleListViewClick = ({ id }) => {
    // window.fcWidget.hide();
    setPurchaseOrderId(id);
  };

  const onModalClose = (vendorIdentifier) => {
    setPurchaseOrderId(undefined);
    if (vendorIdentifier) {
      loadStatusTileContent();
      generateUpdateExpansionStateCallback(vendorIdentifier, true);
    }
    // window.fcWidget.show();
  };

  // Status Tiles
  const renderStatusTileTitle = (tileInfo) => {
    return (
      <div>
        <div
          className={Style.summary_tile_color_bar}
          style={{ backgroundColor: tileInfo.color }}
        />
        <div className={`${Style.summary_tile_top_content}`}>
          <p
            className={Style.top_content_text}
            style={{ color: tileInfo.color }}
          >
            {tileInfo.display_name}
          </p>
        </div>
      </div>
    );
  };

  const renderStatusTileTop = (tileInfo) => {
    const data = statusTileData[tileInfo.id];
    if (!data || Object.keys(data)?.length === 0) {
      return <LoadingCircle shouldCenter />;
    }
    return (
      <div className={`${Style.summary_tile_top_content}`}>
        {data.loading && <LoadingCircle shouldCenter />}
        {!data.loading && (
          <>
            <p className={Style.top_content_text}>{String(formatCurrency(data.cost || 0, "USD", 2))}</p>
            <p className={Style.top_content_text}>
              {`${String(formatNumber(data.quantity || 0, 0))} ${tileInfo?.quantity_units}${
                data.quantity !== 1 ? "s" : ""
              }`}
            </p>
            <p className={Style.bottom_content_text}>
              {`${String(formatNumber(data.count || 0, 0))} Purchase Order${data.count !== 1 ? "s" : ""}`}
            </p>
          </>
        )}
      </div>
    );
  };

  const renderOverviewContent = () => {
    return (
      <div className={Style.overview_right_container}>
        {renderStatusTiles()}
        {renderKpiTiles()}
      </div>
    );
  };

  const getSideFilterStructureMap = () => {
    const LOADING = [{ LOADING: "LOADING" }];
    // TODO improve this impl
    return {
      poDate: {
        id: "date",
        label: "PO Date",
        index: 0,
        type: "dropdown",
        idList: [],
        checkboxList: [
          {
            label: "N/A",
            value: String(filterPanelDateMin),
            style: { marginLeft: "0px" },
            dropdownOptions: [
              { value: "Last 7 days", id: String(ONE_DAY_MS * 7) },
              { value: "Last 30 days", id: String(ONE_DAY_MS * 30) },
              { value: "Last 3 months", id: String(ONE_DAY_MS * 90) },
              { value: "Last 6 months", id: String(ONE_DAY_MS * 180) },
              { value: "Last year", id: String(ONE_DAY_MS * 365) },
              { value: "All", id: String(0), default: true }
            ]
          }
        ]
      },
      vendor: {
        id: "vendor",
        label: "Vendor",
        index: 1,
        idList: [],
        checkboxList: masterVendorNameList
          ? (masterVendorNameList || []).map((partner) => {
              const partnerCopy = { ...partner };
              partnerCopy.label = partner?.name || partner?.identifier;
              partnerCopy.value = false;
              (filterPanelVendors || []).forEach((vendor) => {
                if (vendor.id === partner.id && vendor?.value) {
                  partnerCopy.value = true;
                }
              });
              return partnerCopy;
            })
          : LOADING
      },
      partNumber: {
        id: "partNumber",
        label: "Part Number",
        index: 2,
        idList: [],
        checkboxList: masterItemTypeList
          ? (masterItemTypeList || []).map((itemType) => {
              const itemTypeCopy = { ...itemType };
              let label = itemType?.identifier || "";
              label = label.trim();
              itemTypeCopy.label = label;
              itemTypeCopy.value = false;
              (filterPanelItemTypes || []).forEach((partNumber) => {
                if (partNumber.id === itemType.id && partNumber?.value) {
                  itemTypeCopy.value = true;
                }
              });
              return itemTypeCopy;
            })
          : LOADING
      },
      buyer: {
        id: "buyer",
        label: "Buyer",
        index: 3,
        idList: [],
        checkboxList: masterBuyerNameList
          ? (masterBuyerNameList || []).map((buyer) => {
              const buyerCopy = { ...buyer };
              buyerCopy.label = getBuyerDisplayStringFromObject(buyer);
              buyerCopy.value = false;
              (filterPanelBuyers || []).forEach((selectedBuyer) => {
                if (selectedBuyer.id === buyer.id && selectedBuyer?.value) {
                  buyerCopy.value = true;
                }
              });
              return buyerCopy;
            })
          : LOADING
      },
      fulfillment: {
        id: "fulfillment",
        label: "Received Status",
        index: 4,
        idList: [],
        checkboxList: fulfillmentStatusMetadata?.length
          ? (fulfillmentStatusMetadata || []).map((fulfillmentStatus) => {
              const fulfillmentStatusCopy = {};
              fulfillmentStatusCopy.id = fulfillmentStatus.id;
              fulfillmentStatusCopy.label = fulfillmentStatus.display_name;
              fulfillmentStatusCopy.value = false;
              (filterPanelStatuses || []).forEach((selected) => {
                if (selected.id === fulfillmentStatus.id && selected?.value) {
                  fulfillmentStatusCopy.value = true;
                }
              });
              return fulfillmentStatusCopy;
            })
          : LOADING
      }
    };
  };

  const updateFilterPanelState = (newSideFilterStructureMap) => {
    const {
      vendor = { checkboxList: [] },
      partNumber = { checkboxList: [] },
      buyer = { checkboxList: [] },
      fulfillment = { checkboxList: [] },
      poDate = { checkboxList: [] }
    } = newSideFilterStructureMap;
    setFilterPanelVendors(
      vendor.checkboxList.filter((item) => {
        return item.value;
      })
    );
    setFilterPanelItemTypes(
      partNumber.checkboxList.filter((item) => {
        return item.value;
      })
    );
    setFilterPanelBuyers(
      buyer.checkboxList.filter((item) => {
        return item.value;
      })
    );
    setFilterPanelDateMin(parseInt(poDate.checkboxList[0]?.value));
    setFilterPanelStatuses(
      fulfillment.checkboxList.filter((item) => {
        return item.value;
      })
    );
    setPaginators([]);
  };

  const renderFilterComponent = () => {
    return (
      <CheckBoxListComponent
        filterStructureMap={getSideFilterStructureMap()}
        sortByLabel={false}
        onChange={(newSideFilterStructureMap) => {
          updateFilterPanelState(newSideFilterStructureMap);
        }}
      />
    );
  };

  const renderStatusTiles = () => {
    return (
      <SummaryTilesHolder
        summaryTileMetadataList={orderStatusMetadata}
        showBadgeContainer={false}
        renderSummaryTileComponent={(data) => {
          return (
            <SummaryTileComponent
              key={data.id}
              tileInfo={data}
              isFilterEnabled={statusTileState.current?.locationSummaryTileMap[data.id]}
              shouldShowBadgeContainer={false}
              onClickFn={handleFilterView}
              titleRenderFunction={renderStatusTileTitle}
              topContentRenderFunction={renderStatusTileTop}
              bottomContentRenderFunction={() => {
                return null;
              }}
            />
          );
        }}
      />
    );
  };

  const renderKpiTiles = () => {
    return (kpiTileMetadata || []).map(({ id, label, quantity_units = "", num_decimal_places = 2 }) => {
      const data = kpiTileData[id] || {};
      const value = data.value !== undefined ? formatCurrency(data.value, "USD", num_decimal_places) : " -- ";
      const suffix = quantity_units.length ? ` / ${quantity_units}` : "";
      return (
        <div
          key={id}
          className={Style.overview_right_container_item}
        >
          <p className={`${Style.top_content_text} ${Style.kpi_tile_title}`}>{label}</p>
          {data.loading && <LoadingCircle shouldCenter />}
          {!data.loading && (
            <>
              <p className={`${Style.top_content_text} ${Style.top_content_text_lg} ${Style.kpi_text}`}>
                {`${value}${suffix}`}
              </p>
            </>
          )}
        </div>
      );
    });
  };

  const handleFilterView = (tileId) => {
    const { locationSummaryTileMap } = statusTileState.current;

    const statusTileStateCopy = { ...statusTileState.current };
    let { filterViewEnabled } = statusTileStateCopy;
    const statusTileMapCopy = locationSummaryTileMap;
    const tileNames = Object.keys(statusTileMapCopy);

    if (!filterViewEnabled) {
      tileNames.forEach((tile) => {
        // enable only the tile clicked and disable all others
        statusTileMapCopy[tile] = tile === tileId;
      });
    } else {
      // toggle on/off the summaryTile
      statusTileMapCopy[tileId] = !statusTileMapCopy[tileId];

      // get all active tiles
      const enabledTiles = tileNames.filter((tile) => {
        return statusTileMapCopy[tile] === true;
      });

      // if only one tile is enabled and that tile is clicked, enable all tiles (get out of filter view)
      if (enabledTiles.length < 1) {
        tileNames.forEach((key) => {
          statusTileMapCopy[key] = true;
        });
      }
    }

    filterViewEnabled = isFilterViewEnabled(statusTileMapCopy);

    statusTileState.current = {
      locationSummaryTileMap: { ...statusTileMapCopy },
      filterViewEnabled
    };

    const vendorListToUse = filterPanelVendors?.length ? filterPanelVendors : vendorNameList;
    vendorListToUse.forEach((someVendor) => {
      resetPaginator(someVendor?.identifier);
    });

    recomputeQueryString();
  };

  const exportCSV = async () => {
    setContentLoading(true);
    const finalDataList = [];
    const enabledStatusSetList = getEnabledStatusTileSetList();

    // get ID's of active Filter Panel selections
    const fpStatuses = filterPanelStatuses.map((filterPanelStatusObject) => {
      return filterPanelStatusObject?.id;
    });
    const fpBuyers = filterPanelBuyers.map((filterPanelBuyerObject) => {
      return filterPanelBuyerObject?.identifier;
    });
    const fpItemTypes = filterPanelItemTypes.map((filterPanelItemTypeObject) => {
      return filterPanelItemTypeObject?.identifier;
    });
    const vendorIdentifiers = (filterPanelVendors?.length ? filterPanelVendors : vendorNameList).map((vendorInfo) => {
      return vendorInfo?.identifier;
    });

    const promiseList = [];
    vendorIdentifiers.forEach((vid) => {
      const sorter = lookupSorter(vid)?.[vid];
      promiseList.push(
        purchaseOrderClient.getPurchaseOrdersGivenVendorPaginationSortAndFilters(
          [vid],
          0,
          -1,
          sorter?.key || "identifier",
          sorter?.direction || "incr",
          filterInput,
          filterInputType,
          enabledStatusSetList,
          fpStatuses,
          fpBuyers,
          fpItemTypes,
          filterPanelDateMin !== 0 ? Date.now() - filterPanelDateMin : 0
        )
      );
    });

    await Promise.all(promiseList).then((responses) => {
      responses.forEach((response) => {
        const partners = response?.partners || [];
        partners.forEach((partner) => {
          const purchaseOrdersGivenVendor = partner?.associatedWithPurchaseOrder || [];
          const current = [];
          purchaseOrdersGivenVendor.forEach((po) => {
            po.vendor = partner.name || partner.identifier;
            // use issuedBy connection to populate Buyer field
            if (po.issuedBy?.length > 0) {
              const person = po.issuedBy[0];
              po.buyer = getBuyerDisplayStringFromObject(person);
            }
            // Remove times from dates by formatting
            po.scheduled_ship_date = po.scheduled_ship_date
              ? String(moment(po.scheduled_ship_date).format("MM/DD/YYYY"))
              : "";
            po.fulfillment_date = po.fulfillment_date ? String(moment(po.fulfillment_date).format("MM/DD/YYYY")) : "";
            po.creation_date = po.creation_date ? String(moment(po.creation_date).format("MM/DD/YYYY")) : "";
            current.push(po);
          });
          finalDataList.push({
            headerTitle: partner.name || partner.identifier,
            dataList: current
          });
        });
      });
    });
    setContentLoading(false);
    return finalDataList;
  };

  if (pageLoading) {
    return <LoadingCircle />;
  }

  /**
   * Performs filtering on content rows before display.
   * Status Tile Filter checks if the Status of the inputted row
   * matches the Status of an active (visible) Status Tile.
   * If Item's status doesn't match any Active ==> Reject.
   *
   * @param {*} textFilterString Ignored.
   * @param {*} dataItem Purchase Order in question
   * @returns True if the inputted row should be displayed given the current
   * aggregate filter state; False otherwise
   */
  const listViewFilterFunc = (textFilterString, dataItem) => {
    // Status Tile Filter
    const statusMap = statusTileState.current?.locationSummaryTileMap || {};
    const activeTiles = Object.keys(statusMap).filter((status) => {
      return statusMap[status];
    });
    if (activeTiles?.length && activeTiles.length < 3) {
      // TODO MAGIC NUMBER
      // i.e. there is at least one tile disabled for us to reject upon
      const found = activeTiles.reduce((acc, active) => {
        const displayStatus = dataItem.order_status;
        if (displayStatus.toLowerCase() === active.toLowerCase()) {
          return true;
        }
        return acc;
      }, false);
      if (!found) {
        return found;
      } // don't accept until text filter has run!
    }
    return true;
  };

  return (
    <>
      <TrackPageComponent
        filterFunc={() => {
          return true;
        }}
        defaultFilterInputType="identifier"
        listViewFilterFunc={listViewFilterFunc}
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        statusTab={currentSideTab}
        statusViewTabStructure={getStatusViewTabStructure()}
        removeFilterClick={(ids) => {
          const numSelections =
            (filterPanelDateMin !== 0 ? 1 : 0) +
            filterPanelVendors.length +
            filterPanelItemTypes.length +
            filterPanelBuyers.length +
            filterPanelStatuses.length;
          if (ids.length === numSelections) {
            setFilterPanelDateMin(0);
            setFilterPanelVendors([]);
            setFilterPanelItemTypes([]);
            setFilterPanelBuyers([]);
            setFilterPanelStatuses([]);
            return;
          }
          let listToManipulate = [];
          let setter = null;
          ids.forEach((id) => {
            const s = String(id);
            let item = null;
            if (s.startsWith("vendor")) {
              listToManipulate = [...filterPanelVendors];
              item = s.substring("vendor".length);
              setter = setFilterPanelVendors;
            }
            if (s.startsWith("buyer")) {
              listToManipulate = [...filterPanelBuyers];
              item = s.substring("buyer".length);
              setter = setFilterPanelBuyers;
            }
            if (s.startsWith("partNumber")) {
              listToManipulate = [...filterPanelItemTypes];
              item = s.substring("partNumber".length);
              setter = setFilterPanelItemTypes;
            }

            if (s.startsWith("fulfillment")) {
              listToManipulate = [...filterPanelStatuses];
              item = s.substring("fulfillment".length);
              setter = setFilterPanelStatuses;
            }

            if (s.startsWith("date")) {
              setFilterPanelDateMin(0);
              setPaginators([]);
              setSorters([]);
              return;
            }

            let removalIndex = -1;
            listToManipulate.forEach((fullObject, i) => {
              if (String(fullObject?.label) === item) {
                removalIndex = i;
              }
            });
            if (removalIndex !== -1) {
              listToManipulate.splice(removalIndex, 1);
            }
            updateFilterPanelState(getSideFilterStructureMap());
            setVendorNameList(masterVendorNameList);
            setter(listToManipulate);
            setPaginators([]);
            setSorters([]);
          });
        }}
        currentFilterList={Object.keys(getSideFilterStructureMap()).reduce((accumulator, eachKey) => {
          const { checkboxList, type, id } = getSideFilterStructureMap()[eachKey];
          checkboxList.forEach((eachCheckbox) => {
            let value = eachCheckbox?.value;
            let label = eachCheckbox?.label;
            if (type === "dropdown") {
              value = filterPanelDateMin;
              (eachCheckbox?.dropdownOptions || []).forEach((option) => {
                if (String(option?.id) === String(value)) {
                  label = option?.value;
                }
              });
            }
            if (value) {
              accumulator.push({ id: String(id) + String(label), label, sort: eachKey });
            }
          });
          return accumulator;
        }, [])}
        listViewMode // TODO
        renderDataList={vendorContentMetadataList}
        defaultNumExpandedHeaders={defaultNumExpandedHeaders}
        headerBasedContentList={vendorContentList}
        shouldShowSeeMore={isContentSeeMoreNeeded}
        shouldSortHeaders={false} // Headers (Vendors) are sorted by us
        preferCollapse={false} // Prefer Expand
        seeMoreCallback={onClickContentSeeMore}
        headerMetricsList={getVendorMetricsMetadata()}
        headerMetricsData={vendorMetricsData}
        headerType="Vendors"
        disableHeaderSortButton
        getNumSubContentItems={getNumPOsGivenVendorAndStatusTileFilter}
        getLastPage={getLastVisitedContentPage}
        shouldUseDashForNullListViewContent
        contentLoading={contentLoading}
        title={pageTitle}
        titleIcon={
          <PurchaseOrderIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        buttonTitle="+ Purchase Order"
        buttonFunction={() => {
          // window.fcWidget.hide();
          setPurchaseOrderId("NEW");
        }}
        overviewComponent={renderOverviewContent()}
        filterComponent={renderFilterComponent()}
        currentViewTab="vendor"
        viewTabStructure={getViewTabStructure()}
        viewTabLabel="" // default ViewTabComponent adds text; avoid
        viewTabButtonClassname={Style.view_tab_btn}
        viewTabButtonActiveClassname={Style.view_tab_btn_active}
        listViewStructure={getContentListViewStructure()}
        contentSortSchema={getContentSortSchema()}
        flipSortOrder
        handleListViewClick={handleListViewClick}
        updateFilterFunction={updateFilterStateCallback}
        getNumRowsPerPage={getNumberOfRowsPerPageForPartner}
        generatePaginationUpdateFunc={generateUpdatePaginationStateCallback}
        generateColumnSortUpdateFunc={generateUpdateColumnSortStateCallback}
        generateExpansionUpdateFunc={generateUpdateExpansionStateCallback}
        getCurrentSorterFunction={lookupSorterForListView}
        shouldResetSubContentPageOnRefresh={false}
        onExportCSV={exportCSV}
        numOfItemsPerPage={10}
        getContentLengthForListView={getNumPOsGivenVendorStatusAndTextFilter}
        getEnabledStatuses={getEnabledStatusTileSetList}
      />
      <PurchaseOrderDetailModalFeature
        show={!!purchaseOrderId}
        purchaseOrderId={purchaseOrderId}
        onClose={onModalClose}
        history={history}
      />
    </>
  );
};
export default withRouter(PurchaseOrderTrackPageFeature);
