import { STAGES } from "../../../../../../data/constants";

export const getTransferOrderUpdates = (transferOrders, itemTypeReports = [], stage) => {
  const itemTypeIdToEpcMap = itemTypeReports.reduce((acc, report) => {
    const { id, epcToItemMap } = report || {};
    acc[id] = epcToItemMap;
    return acc;
  }, {});

  const transferOrderUpdates = transferOrders.map((transferOrder) => {
    const { id: transferOrderId, identifier: transferOrderIdentifier, hasItemsEntry } = transferOrder;

    const itemTypeEvents = [];
    hasItemsEntry.forEach((itemEntry) => {
      const {
        id: itemsEntryId,
        ofItemType = [],
        hasTransferItemState = [],
        quantity_total: quantityTotal,
        quantity_staged: quantityStaged = 0,
        quantity_in_progress: quantityInProgress = 0,
        quantity_received: quantityReceived = 0,
        quantity_verified: quantityVerified = 0
      } = itemEntry;

      const stageToCounts = {
        [STAGES.STAGE]: quantityStaged + quantityInProgress + quantityReceived + quantityVerified,
        [STAGES.SHIP]: quantityInProgress + quantityReceived + quantityVerified,
        [STAGES.RECEIVE]: quantityReceived + quantityVerified,
        [STAGES.VERIFY]: quantityVerified
      };
      const expectedCount = quantityTotal - stageToCounts[stage.id];

      const itemType = ofItemType[0] || {};
      const { id: itemTypeId, identifier: itemTypeIdentifier } = itemType;

      const epcMap = itemTypeIdToEpcMap[itemTypeId] || {};

      let numItemsToAdd = Math.min(quantityTotal, Object.keys(epcMap).length);
      const sensorProfileIds = [];

      // Add scanned items that are already associated with this transfer order first
      hasTransferItemState.forEach((itemState) => {
        const { ofItem: items = [], transfer_status: transferStatus } = itemState;
        const { vid, id: sensorProfileId } = items?.[0]?.hasSensorProfile?.[0] || {};

        if (epcMap[vid] && epcMap[vid].scanned) {
          sensorProfileIds.push(sensorProfileId);
        }

        // Decrement by the number of items associated with the TO even if they were not scanned since we
        // do not want to add more items if the TO already expects certain items
        if (transferStatus === stage.submitStatus) {
          numItemsToAdd--;
        }
      });

      // Add items that are not yet associated with any transfer order
      Object.values(epcMap).forEach((item) => {
        const { isAssociatedWithOrder, scanned, sensorProfileId } = item;
        if (numItemsToAdd > 0 && scanned && !isAssociatedWithOrder) {
          numItemsToAdd--;
          sensorProfileIds.push(sensorProfileId);
        }
      });

      // Remove items that have been added to a transfer order from epcMap
      const newEpcMap = Object.entries(epcMap).reduce((acc, [vid, item]) => {
        if (!sensorProfileIds.includes(item.sensorProfileId)) {
          acc[vid] = item;
        }

        return acc;
      }, {});

      itemTypeIdToEpcMap[itemTypeId] = newEpcMap;

      itemTypeEvents.push({
        itemsEntryId,
        itemTypeIdentifier,
        sensorProfileIds,
        expectedCount,
        scannedCount: sensorProfileIds.length
      });
    });

    return {
      id: transferOrderId,
      identifier: transferOrderIdentifier,
      itemTypeEvents
    };
  });

  return transferOrderUpdates;
};
