import React, { useEffect, useMemo } from "react";
import { useStore } from "react-hookstore";
import Style from "./Kiosk.module.css";
import { ReactComponent as GoodsReceiptIcon } from "../../assets/icons/goods-receipt.svg";
import ActionsPage from "./features/actions-page";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import xemelgoStyle from "../../styles/variable";
import StatusPopupComponent, { STATUS_OPTIONS } from "../../components/status-popup-component";
import useKioskConfigContext, { KioskConfigContextProvider } from "./contexts/kiosk-config-context";
import useKioskStateContext, { KioskStateContextProvider } from "./contexts/kiosk-state-context";
import HeaderRightComponent from "./components/header-right-component";
import Breadcrumb from "../../components/breadcrumb";
import useKioskSearchParams, { SEARCH_PARAM_NAME } from "./hooks/use-kiosk-search-params";
import { fullScreenModeStore } from "../../state-managements/stores/full-screen-mode-store";
import SelectedKioskFeature from "./features/selected-kiosk-feature";

const mainColor = xemelgoStyle.theme.INVENTORY_PRIMARY;
const secondaryColor = xemelgoStyle.theme.INVENTORY_SECONDARY;

const Kiosk = () => {
  const { actionId, fullscreenMode, setFullscreenMode } = useKioskSearchParams();
  const [, setFullscreenModeStore] = useStore(fullScreenModeStore);

  const { title, action } = useKioskConfigContext();
  const { setItemByTag, setItemTypeReports, submitStatus, setSubmitStatus, statusMessage, readStatus } =
    useKioskStateContext();

  const breadscumbsDataList = useMemo(() => {
    const rootPath = `/check-out${fullscreenMode ? `?${SEARCH_PARAM_NAME.fullscreenMode}=${fullscreenMode}` : ""}`;
    const defaultBreadscrumbsDataList = [
      {
        title,
        path: rootPath
      }
    ];

    if (actionId) {
      defaultBreadscrumbsDataList.push({
        title: action.label || ""
      });
    }
    return defaultBreadscrumbsDataList;
  }, [actionId, fullscreenMode, title, action]);

  useEffect(() => {
    setFullscreenModeStore(fullscreenMode);
  }, [fullscreenMode]);

  useEffect(() => {
    if (actionId) {
      // window.fcWidget.hide();
    } else {
      setItemByTag({});
      setItemTypeReports([]);
      // window.fcWidget.show();
    }
  }, [actionId]);

  return (
    <ScreenFrame
      verticalExpandDisabled
      titleIconComponent={
        <GoodsReceiptIcon
          width={25}
          height={25}
          style={{ color: mainColor }}
        />
      }
      title={<Breadcrumb dataList={breadscumbsDataList} />}
      titleRightComponent={
        <HeaderRightComponent
          fullscreenMode={fullscreenMode}
          onChangeFullscreenMode={setFullscreenMode}
          status={readStatus}
        />
      }
      color={mainColor}
      secondaryColor={secondaryColor}
    >
      <div className={fullscreenMode ? Style.fullscreen_main_container : Style.main_container}>
        {actionId ? <SelectedKioskFeature /> : <ActionsPage />}
      </div>
      <StatusPopupComponent
        showPopup={submitStatus !== STATUS_OPTIONS.NONE}
        closeOnClickOutside
        message={statusMessage}
        status={submitStatus}
        onPopupClose={() => {
          setSubmitStatus(STATUS_OPTIONS.NONE);
        }}
        isCenterPopup
      />
    </ScreenFrame>
  );
};

export default () => {
  return (
    <KioskStateContextProvider>
      <KioskConfigContextProvider>
        <Kiosk />
      </KioskConfigContextProvider>
    </KioskStateContextProvider>
  );
};
