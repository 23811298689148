import React, { useMemo } from "react";
import Style from "../../../../../../Kiosk.module.css";
import MultiInputForm from "../../../../../../../../components/multi-input-form";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";

export const SidePanel = () => {
  const { panelValues, setPanelValues, optionTemplateMap, loading } = useKioskStateContext();
  const { sidePanelAttributes } = useKioskConfigContext();

  const formattedPanelAttributes = useMemo(() => {
    return sidePanelAttributes.map((attribute) => {
      const { optionTemplate } = attribute;
      const options = optionTemplateMap[optionTemplate] || attribute.options || [];
      return {
        ...attribute,
        value: panelValues[attribute.id],
        options
      };
    });
  }, [sidePanelAttributes, panelValues, optionTemplateMap]);

  const onChange = (id, value) => {
    const newPanelValues = { ...panelValues };
    newPanelValues[id] = value;
    setPanelValues(newPanelValues);
  };

  return (
    <div className={Style.side_panel_container}>
      <MultiInputForm
        formFields={formattedPanelAttributes}
        containerClassName={Style.multi_input_container}
        fieldContainerClassName={Style.side_panel_field_container}
        onChange={onChange}
        isLoading={loading}
      />
    </div>
  );
};
