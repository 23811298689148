import { binaryToHex, getFormattedDate } from "../../common/Utilities";

// TODO: Tag length should probably be retrieved from template to adapt to different tags/printers
export const DEFAULT_TAG_LENGTH = 24;

const XEMELGO_HEADER_BITS = "010111";
const EPC_FORMAT_BITS = "00";

const CLASS_BITS = {
  Traveller: "0000",
  Part: "0001",
  Inventory: "0010",
  Material: "0011",
  Stock: "0100",
  Asset: "0101",
  Container: "0110",
  Collection: "0111",
  Package: "1000"
};

export const generateTag = (numCharacters = 24, tagClass, customPrefix) => {
  return generateTags(numCharacters, 1, tagClass, customPrefix)[0];
};

export const generateTags = (numCharacters = 24, numTags = 1, tagClass, customPrefix) => {
  if (numTags < 1) {
    throw new Error("Number of tags must be at least 1");
  } else if (!CLASS_BITS[tagClass]) {
    throw new Error(`Invalid tag class: ${tagClass}`);
  }

  const classBits = CLASS_BITS[tagClass];
  const customPrefixBits = hexStringToBinary(customPrefix);

  let epc = binaryToHex(`${XEMELGO_HEADER_BITS}${EPC_FORMAT_BITS}${classBits}${customPrefixBits}`);

  const availableBytes = numCharacters - epc.length - `${numTags}`.length;
  const timestamp = Date.now();

  if (availableBytes >= 15) {
    const timeFormat = "YYMMDDHHmmssSSS";
    epc += getFormattedDate(timestamp, timeFormat);
  } else if (availableBytes >= 13) {
    epc += timestamp;
  } else {
    throw new Error(
      `EPC exceeds maximum character limit of ${numCharacters}. Current length is ${
        epc.length
      } characters for the header, ${
        numTags.toString().length
      } characters for the serial number, and at least ${availableBytes} characters for the timestamp. At least 12 characters are required for the timestamp.`
    );
  }

  const tags = [];
  for (let i = 1; i <= numTags; i++) {
    const paddingLength = numCharacters - `${epc}${i}`.length;
    const paddedString = "0".repeat(paddingLength) + i;
    tags.push(`${epc}${paddedString}`);
  }

  return tags;
};

const isValidHexadecimal = (str) => {
  const hexRegex = /^([0-9a-fA-F]*)$/;
  return hexRegex.test(str);
};

const hexStringToBinary = (hexStr = "") => {
  if (!isValidHexadecimal(hexStr)) {
    throw new Error("Invalid hexadecimal string");
  }

  const hexToBinaryMap = {
    0: "0000",
    1: "0001",
    2: "0010",
    3: "0011",
    4: "0100",
    5: "0101",
    6: "0110",
    7: "0111",
    8: "1000",
    9: "1001",
    a: "1010",
    b: "1011",
    c: "1100",
    d: "1101",
    e: "1110",
    f: "1111",
    A: "1010",
    B: "1011",
    C: "1100",
    D: "1101",
    E: "1110",
    F: "1111"
  };

  let binaryStr = "";
  for (const char of hexStr) {
    binaryStr += hexToBinaryMap[char];
  }

  return binaryStr;
};
