import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Style from "./ContainerAssociationModal.module.css";
import PrinterSelectionComponent from "../../../../../../../../components/printer-selection-component";
import Modal from "../../../../../../../../components/modal";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import useTransferOrderConfigContext from "../../contexts/transfer-order-config-context";
import { STAGES } from "../../data/constants";

const FEATURE_ID = "container-association";

export const ContainerAssociationModal = ({
  numItems,
  printService,
  onCancel,
  onConfirm,
  containerIdentifier,
  stageActionLabel
}) => {
  const { isPrintReady } = printService;
  const { panelValues } = useKioskStateContext();
  const { action, printOptions } = useKioskConfigContext();
  const { customText } = useTransferOrderConfigContext();

  const modalText = useMemo(() => {
    // In order creation, the container can be selected beforehand in the setup step
    if (containerIdentifier) {
      const printText = printOptions.enabled
        ? printOptions.isPrintLaterEnabled
          ? " Please state if you want to print a tag now or later."
          : " Please print a tag."
        : "";

      return (
        <p>
          {`You are about to associate `}
          <span className={Style.bold_text}>{numItems}</span>
          {` items to `}
          <span className={Style.bold_text}>{`${containerIdentifier}.`}</span>
          {printText}
        </p>
      );
    }

    const printText = printOptions.enabled
      ? printOptions.isPrintLaterEnabled
        ? " and state if you want to print a tag now or later"
        : " and print a tag"
      : "";

    return (
      <p>
        {`You are about to ${stageActionLabel} `}
        <span className={Style.bold_text}>{numItems}</span>
        {` items for `}
        <span className={Style.bold_text}>{`${panelValues.transferOrderIdentifiersString}.`}</span>
        {` Please associate it with a ${customText.container}${printText}.`}
      </p>
    );
  }, [numItems, customText, containerIdentifier]);

  return (
    <Modal
      title={action.label}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmDisabled={printOptions.enabled && !isPrintReady}
      confirmButtonText="Next"
    >
      <div className={Style.container}>
        {modalText}
        {printOptions.enabled && (
          <div className={Style.printer_component}>
            <PrinterSelectionComponent
              printService={printService}
              hideLabelSelection={!!printOptions.printConfig}
              solutionType={FEATURE_ID}
              isPrintLaterEnabled={printOptions.isPrintLaterEnabled}
              showTitle={false}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

ContainerAssociationModal.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  numItems: 0,
  printService: {},
  containerIdentifier: "",
  stageActionLabel: STAGES.VERIFY
};

ContainerAssociationModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  numItems: PropTypes.number,
  printService: PropTypes.shape({
    isPrintReady: PropTypes.bool
  }),
  containerIdentifier: PropTypes.string,
  stageActionLabel: PropTypes.string
};
