import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Style from "./EpcTable.module.css";
import { NOT_SCANNED_STATUS, SCANNED_STATUS } from "./data/constants";
import PaginatedListTableWithTypes from "../../../../../../../../../../components/paginated-list-table-with-types";
import { ITEM_CATEGORY_TO_ROUTE_NAME } from "../../../../../../../../../../routes/data/constants";
import { DEFAULT_EPC_TABLE_HEADERS } from "../../../../../transfer-order-actions/data/constants";

export const EpcTable = ({ headers, items, onClearItem }) => {
  const history = useHistory();

  const augmentedItems = items.map((item) => {
    return {
      ...item,
      rowOptions: [
        {
          id: "clear",
          label: "Clear"
        }
      ],
      status: [item.scanned ? SCANNED_STATUS : NOT_SCANNED_STATUS],
      statusText: item.scanned ? 1 : 0
    };
  });

  return (
    <div className={Style.table_container}>
      <PaginatedListTableWithTypes
        headers={headers}
        getTitleURLCallbackFn={(data) => {
          const { id, category } = data;

          if (id && ITEM_CATEGORY_TO_ROUTE_NAME[category]) {
            return `${ITEM_CATEGORY_TO_ROUTE_NAME[category]}/item/detail?itemId=${id}`;
          }

          return history.location.pathname;
        }}
        headerRowContainerClassName={Style.header_row}
        dataList={augmentedItems}
        itemRowContainerClassName={Style.item_row}
        paginatorLocation="top"
        paginatorClassName={Style.paginator}
        showRowOptions
        onRowOptionClick={(_option, item) => {
          onClearItem(item.vid);
        }}
        numItemsPerPage={5}
        openLinkInNewTab
        sortIdReplacementMap={{
          status: "statusText"
        }}
      />
    </div>
  );
};

EpcTable.defaultProps = {
  headers: DEFAULT_EPC_TABLE_HEADERS,
  items: [],
  onClearItem: () => {}
};

EpcTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string })),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onClearItem: PropTypes.func
};
