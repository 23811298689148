import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import SearchDropdown from "../../../../../../../../../../components/SearchDropdown/SearchDropdown";
import KioskStyle from "../../../../../../../../Kiosk.module.css";
import Style from "./PicklistVerificationSetup.module.css";
import TransferOrderDropdown from "./features/transfer-order-dropdown";
import useTransferOrderConfigContext from "../../../../contexts/transfer-order-config-context";
import ContainerSelectionDropdown from "../../../container-selection-dropdown";

export const PicklistVerificationSetup = ({ onConfirm }) => {
  const { stageOptions, customText, containerAssociationOptions } = useTransferOrderConfigContext();

  const [stage, setStage] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [existingContainer, setExistingContainer] = useState();
  const [newContainer, setNewContainer] = useState();

  useEffect(() => {
    if (stageOptions.length === 1) {
      setStage(stageOptions[0]);
    }
  }, []);

  const selectedOptions = useMemo(() => {
    return Object.values(selectedOptionsMap);
  }, [selectedOptionsMap]);

  const isContainerSelected =
    !containerAssociationOptions.enabled || existingContainer?.identifier || newContainer?.identifier;
  const isVidEntered =
    !containerAssociationOptions.manualTagEntry || existingContainer?.identifier || newContainer?.vid;

  return (
    <>
      <div className={Style.container}>
        <div className={Style.inputs_container}>
          {stageOptions.length > 1 && (
            <div>
              <div className={Style.text}>
                Stage:
                <p className={Style.required_asterisk}>*</p>
              </div>
              <SearchDropdown
                selectedItem={stage}
                options={stageOptions}
                onItemSelected={(newStage) => {
                  setStage(newStage);
                }}
                showIcon
                showAllOptions
              />
            </div>
          )}
          <div>
            <div className={Style.text}>
              {`${customText.transferOrderIdentifierLabel}:`}
              <p className={Style.required_asterisk}>*</p>
            </div>
            <TransferOrderDropdown
              selectedOptionsMap={selectedOptionsMap}
              onSelectedOptionsMapChange={setSelectedOptionsMap}
            />
          </div>
          {containerAssociationOptions.enabled && (
            <ContainerSelectionDropdown
              transferOrderGroupName={selectedOptions
                .map((option) => {
                  return option.label;
                })
                .sort()
                .join(",")}
              onSelectContainer={setExistingContainer}
              newContainer={newContainer}
              onChangeNewContainer={setNewContainer}
            />
          )}
        </div>
      </div>
      <div className={KioskStyle.footer}>
        <button
          type="button"
          className={KioskStyle.footer_button}
          disabled={selectedOptions.length === 0 || !stage || !isContainerSelected || !isVidEntered}
          onClick={() => {
            const selectedTransferOrders = selectedOptions.map((option) => {
              return option.value;
            });

            onConfirm(selectedTransferOrders, stage, existingContainer, newContainer);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

PicklistVerificationSetup.propTypes = {
  onConfirm: PropTypes.func.isRequired
};
