import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import PropTypes from "prop-types";
import Style from "./Modal.module.css";

export const Modal = ({
  title,
  children,
  onConfirm,
  onCancel,
  closeOnClickOutside,
  showCancelButton,
  confirmDisabled,
  confirmButtonText,
  confirmButtonColor,
  cancelButtonText,
  modalDialogClassName
}) => {
  return (
    <BootstrapModal
      show
      onHide={onCancel}
      centered
      dialogClassName={`${Style.modal_dialog} ${modalDialogClassName}`}
      className={Style.modal_container}
      backdrop={closeOnClickOutside ? true : "static"}
      data-cy="modal"
    >
      <div className={Style.modal_top_container}>
        <p className={Style.title_text}>{title}</p>
        {children}
      </div>
      <div className={Style.modal_button_container}>
        <div>
          {showCancelButton && (
            <button
              type="button"
              onClick={onCancel}
              className={`${Style.button} ${Style.grey_button}`}
            >
              {cancelButtonText}
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              onConfirm();
            }}
            className={`${Style.button} ${Style.blue_button}`}
            style={{ backgroundColor: confirmButtonColor }}
            disabled={confirmDisabled}
            data-cy="modal-confirm-button"
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </BootstrapModal>
  );
};

Modal.defaultProps = {
  confirmDisabled: false,
  onCancel: () => {},
  onConfirm: () => {},
  showCancelButton: true,
  closeOnClickOutside: true,
  confirmButtonText: "Confirm",
  confirmButtonColor: "",
  cancelButtonText: "Cancel",
  modalDialogClassName: ""
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  closeOnClickOutside: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
  modalDialogClassName: PropTypes.string
};
