import React, { useEffect } from "react";
import MainPage from "../main-page";
import useKioskStateContext from "../../../../contexts/kiosk-state-context";
import { analyzeItemsForItemTypeReport } from "../../../../utils/item-type-report/itemTypeReportHelpers";
import SubmissionModal from "./features/submission-modal";
import useUserLocationContext from "../../../../../../context/user-location-context";
import useKioskConfigContext from "../../../../contexts/kiosk-config-context";
import { defaultFetchData } from "./utils/default-fetch-data/defaultFetchData";

export const CheckoutTable = () => {
  const { userLocation } = useUserLocationContext();
  const { sidePanelAttributes, readerLocationOptionCategory, locationOptionCategory } = useKioskConfigContext();
  const {
    itemByTag,
    setItemTypeReports,
    setOptionTemplateMap,
    setLocationOptions,
    setReaderOptions,
    setLoading,
    itemTypeReports
  } = useKioskStateContext();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!itemByTag) {
      return;
    }

    const reports = analyzeItemsForItemTypeReport(Object.values(itemByTag));
    setItemTypeReports(reports);
  }, [itemByTag]);

  const fetchData = async () => {
    setLoading(true);

    const { newOptionsTemplateMap, newLocationOptions, newReaderOptions } = await defaultFetchData(
      userLocation,
      sidePanelAttributes,
      readerLocationOptionCategory,
      locationOptionCategory
    );

    setOptionTemplateMap(newOptionsTemplateMap);
    setLocationOptions(newLocationOptions);
    setReaderOptions(newReaderOptions);

    setLoading(false);
  };

  return (
    <>
      <MainPage
        onClearReport={() => {
          setItemTypeReports([]);
        }}
        onClearReportRow={(id) => {
          const reportIndex = itemTypeReports.findIndex((report) => {
            return report.id === id;
          });

          const newReports = [...itemTypeReports];
          newReports.splice(reportIndex, 1);
          setItemTypeReports(newReports);
        }}
      />
      <SubmissionModal />
    </>
  );
};
