import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useStore } from "react-hookstore";
import { userProfileStore } from "state-managements/stores/user-profile-store";
import { fullScreenModeStore } from "../../state-managements/stores/full-screen-mode-store";
import { useDisplayBannerContext } from "../../context/DisplayBannerContext/DisplayBannerContext";
import MainAppComponent from "../../components/main-app-component/MainAppComponent";
import HeaderComponent from "../../components/header-component/HeaderComponent";
import MainNavigationBar from "../main-navigation-bar/MainNavigationBar";
import RenderComponent from "../../components/render-component/RenderComponent";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import MainAppFeatureStyle from "./MainAppFeature.module.css";
import { LocalCacheService } from "../../services/local-cache-service";

const DEFAULT_SIDE_NAVIGATION_COLLAPSED_STATE = false;

const MainAppFeature = ({ render }) => {
  const [userProfile] = useStore(userProfileStore);
  const [fullScreenMode] = useStore(fullScreenModeStore);
  const [sideNavigationCollapsed, setSideNavigationCollapsed] = useState(
    LocalCacheService.getSideNavigationHorizontalCollapsed() || DEFAULT_SIDE_NAVIGATION_COLLAPSED_STATE
  );

  useEffect(() => {
    // if (userProfile) {
    //   window.fcWidget.init({
    //     token: "8b71db32-33e9-4423-916d-966821e989a0",
    //     host: "https://wchat.freshchat.com",
    //     externalId: userProfile.userId
    //   });
    //   window.fcWidget.user.get(function (resp) {
    //     const status = resp && resp.status;
    //     // Status 200 is from Freschat API docs -- It means that there is a user registered using the chat
    //     if (status !== 200) {
    //       window.fcWidget.user.setProperties({
    //         firstName: userProfile.firstName, // user's first name
    //         lastName: userProfile.lastName, // user's last name
    //         email: userProfile.email, // user's email address
    //         phone: userProfile.phoneNumber
    //       });
    //       window.fcWidget.on("user:created", function (onCreatedResp) {
    //         const onCreatedRespStatus = onCreatedResp && onCreatedResp.status;
    //         // TODO: this part of initalization will check if the user exists and if so, we use restoreID to initialize
    //         // chat with previous conversation if logging on to the web app via different machines
    //         if (onCreatedRespStatus === 200) {
    //           // Update restoreId in your database
    //         }
    //       });
    //     }
    //   });
    // }
  }, [userProfile]);

  const testingMode = sessionStorage.getItem("CustomerTestMode") === "Test";
  let mainAppStyle;
  let headerContainerStyle;
  let sideNavigationContainerStyle;

  const { showBanner, setShowBanner, bannerHasError, bannerTitle } = useDisplayBannerContext();

  if (fullScreenMode) {
    if (testingMode) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_full_screen_container_test;
      headerContainerStyle = MainAppFeatureStyle.header_fullscreen_container_test;
    } else {
      mainAppStyle = MainAppFeatureStyle.main_app_component_full_screen_container;
    }
  } else if (testingMode) {
    if (sideNavigationCollapsed) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_test_collapsed;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_test_collapsed;
    } else {
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_test;
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_test;
    }
    headerContainerStyle = MainAppFeatureStyle.header_component_container_test;
  } else {
    if (sideNavigationCollapsed) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_collapsed;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_collapsed;
    } else {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container;
    }
    headerContainerStyle = MainAppFeatureStyle.header_component_container;
  }

  return (
    <MainAppComponent
      containerStyle={mainAppStyle}
      headerComponent={() => {
        return (
          <HeaderComponent
            containerStyle={headerContainerStyle}
            fullScreenMode={fullScreenMode}
          />
        );
      }}
      sideNavigationComponent={() => {
        return (
          !fullScreenMode && (
            <MainNavigationBar
              navbarCollapsed={sideNavigationCollapsed}
              onNavbarCollapse={() => {
                setSideNavigationCollapsed(!sideNavigationCollapsed);
                LocalCacheService.saveSideNavigationHorizontalCollapsed(!sideNavigationCollapsed);
              }}
              containerStyle={sideNavigationContainerStyle}
            />
          )
        );
      }}
      renderComponent={() => {
        return (
          <>
            <div
              className={`${MainAppFeatureStyle.banner_container} ${
                !showBanner && MainAppFeatureStyle.banner_container_collapsed
              }`}
            >
              {showBanner && (
                <DisplayBanner
                  bannerError={bannerHasError}
                  onCloseBanner={() => {
                    setShowBanner(false);
                  }}
                  bannerMessage={bannerTitle}
                />
              )}
            </div>
            <RenderComponent
              containerStyle={MainAppFeatureStyle.render_component_container}
              renderer={render}
            />
          </>
        );
      }}
    />
  );
};

export default withRouter(MainAppFeature);

MainAppFeature.propTypes = {
  render: PropTypes.element.isRequired
};
