import { useEffect, useMemo, useRef } from "react";
import { PubSub } from "@aws-amplify/pubsub";
import { READ_MODE_OPTIONS, EXCLUDED_ANALYTIC_VALUES_SET } from "./data/constants";
import { getTagsSinceTimestamp } from "../../services/get-recent-tags-service";
import { callEdgeOperation, EDGE_OPERATION_MAP } from "./utils/call-ege-operation";
import useAuthenticationContext from "../../context/authentication-context";

export const useMountedReader = (
  edgeApiUrl,
  readMode = READ_MODE_OPTIONS.IOT_TOPIC,
  startStopReader = false,
  disableIngestion = false
) => {
  const { cognitoUser } = useAuthenticationContext();
  const readerSerialToTagMapRef = useRef({});
  const subscriptionRef = useRef();
  const readerToIntervalIdMapRef = useRef({});
  const subscribedReaderSetRef = useRef(new Set());
  const disabledRuleReaderSetRef = useRef(new Set());

  const tenantId = useMemo(() => {
    return cognitoUser?.attributes?.["custom:tenantId"];
  }, [cognitoUser]);

  useEffect(() => {
    return async () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }

      Object.values(readerToIntervalIdMapRef.current).forEach((intervalId) => {
        clearInterval(intervalId);
      });

      const promises = [...subscribedReaderSetRef.current].reduce((acc, detectorSerial) => {
        if (disableIngestion) {
          acc.push(
            callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.ENABLE_INGESTION_RULE, undefined, tenantId)
          );
        }
        if (startStopReader) {
          // TODO: Make this config driven
          acc.push(callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.START));
        }

        return acc;
      }, []);

      await Promise.all(promises);
    };
  }, []);

  const getDetectorSerial = (detectorVid) => {
    return detectorVid?.split("#")?.[0] || "";
  };

  const subscribeToMountedReader = (detectorSerial, readSettings = {}) => {
    if (!detectorSerial || subscribedReaderSetRef.current.has(detectorSerial)) {
      return false;
    }

    switch (readMode) {
      case READ_MODE_OPTIONS.IOT_TOPIC:
        subscribeToIoTTopic(detectorSerial);
        break;
      case READ_MODE_OPTIONS.TAGS_TABLE:
        subscribeToTagsTable(detectorSerial, readSettings);
        break;
      default:
        return false;
    }

    return true;
  };

  const subscribeToIoTTopic = (detectorSerial) => {
    subscribedReaderSetRef.current.add(detectorSerial);

    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    try {
      const options = {
        clientId: `xemelgo-web-client-${cognitoUser.username}-${Date.now()}`,
        debug: true
      };
      readerSerialToTagMapRef.current[detectorSerial] = {};

      subscriptionRef.current = PubSub.subscribe(`devices/${detectorSerial}/tags`, options).subscribe({
        error: (error) => {
          console.error(error);
        },
        next: (data) => {
          const { tags } = data.value;
          tags.forEach((tag) => {
            if (!EXCLUDED_ANALYTIC_VALUES_SET.has(tag.analytic?.value)) {
              readerSerialToTagMapRef.current[detectorSerial][tag.Name] = tag;
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const subscribeToTagsTable = async (detectorSerial, readSettings) => {
    const { apiUrl, queryFrequencyInSeconds = 2 } = readSettings;

    if (!apiUrl) {
      throw new Error("Missing API URL is for reading tags.");
    }

    let lastQueryTimestamp = Date.now() - 1000 * queryFrequencyInSeconds;

    readerSerialToTagMapRef.current[detectorSerial] = {};
    subscribedReaderSetRef.current.add(detectorSerial);

    const intervalId = setInterval(async () => {
      const tags = await getTagsSinceTimestamp(apiUrl, detectorSerial, lastQueryTimestamp);
      tags.forEach((tag) => {
        if (!EXCLUDED_ANALYTIC_VALUES_SET.has(tag.analytic?.value)) {
          readerSerialToTagMapRef.current[detectorSerial][tag.Name] = tag;
        }
      });
      lastQueryTimestamp = Date.now();
    }, 1000 * queryFrequencyInSeconds);

    readerToIntervalIdMapRef.current[detectorSerial] = intervalId;
  };

  const getReaderTagMap = (detectorVid, clearTags = false) => {
    if (!detectorVid) {
      return {};
    }

    const detectorSerial = getDetectorSerial(detectorVid);
    const tagsMap = readerSerialToTagMapRef.current[detectorSerial] || {};

    if (clearTags) {
      readerSerialToTagMapRef.current[detectorSerial] = {};
    }

    return tagsMap;
  };

  const unsubscribeFromMountedReader = (detectorSerial) => {
    if (!subscribedReaderSetRef.current.has(detectorSerial)) {
      return;
    }

    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    if (readerToIntervalIdMapRef.current[detectorSerial]) {
      clearInterval(readerToIntervalIdMapRef.current[detectorSerial]);
    }

    subscribedReaderSetRef.current.delete(detectorSerial);
  };

  const startMountedReader = async (detectorVid, abortSignal, readSettings) => {
    const detectorSerial = getDetectorSerial(detectorVid);
    subscribeToMountedReader(detectorSerial, readSettings);

    if (disableIngestion) {
      disabledRuleReaderSetRef.current.add(detectorSerial);
      await callEdgeOperation(
        edgeApiUrl,
        detectorSerial,
        EDGE_OPERATION_MAP.DISABLE_INGESTION_RULE,
        abortSignal,
        tenantId
      );
    }

    if (startStopReader) {
      await callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.STOP, abortSignal);
      await callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.START, abortSignal);
    }
  };

  const pauseMountedReader = async (detectorVid, abortSignal) => {
    if (!startStopReader) {
      return;
    }

    const detectorSerial = getDetectorSerial(detectorVid);
    await callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.STOP, abortSignal);
  };

  const resumeMountedReader = async (detectorVid, abortSignal) => {
    if (!startStopReader) {
      return;
    }

    const detectorSerial = getDetectorSerial(detectorVid);
    await callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.START, abortSignal);
  };

  const stopMountedReader = async (detectorVid, abortSignal) => {
    const detectorSerial = getDetectorSerial(detectorVid);

    unsubscribeFromMountedReader(detectorSerial);

    if (disableIngestion) {
      await callEdgeOperation(
        edgeApiUrl,
        detectorSerial,
        EDGE_OPERATION_MAP.ENABLE_INGESTION_RULE,
        abortSignal,
        tenantId
      );

      disabledRuleReaderSetRef.current.delete(detectorSerial);
    }

    if (startStopReader) {
      await callEdgeOperation(edgeApiUrl, detectorSerial, EDGE_OPERATION_MAP.STOP, abortSignal);
    }
  };

  return {
    startMountedReader,
    pauseMountedReader,
    resumeMountedReader,
    stopMountedReader,
    getReaderTagMap
  };
};
