/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { CloseRounded } from "@material-ui/icons";
import { ReactComponent as ReportsIcon } from "../../assets/icons/reports.svg";
import PropTypes from "prop-types";
import { ModalForm } from "../../components/modal-form";
import "./RunReportModalFeature.css";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import { useXemelgoClient } from "../../services/xemelgo-service";
import LoadingCircle from "../../components/loading/LoadingCircle";
import Style from "./RunReportModalFeature.module.css";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import useReportFormFactory from "./hooks/use-report-form-factory/useReportFormFactory";
import xemelgoStyle from "../../styles/variable";

const APP_ID = "reports";

const getRunnableReportTypesForCategory = (reportGroups, category) => {
  return (
    reportGroups[category.id]?.filter((report) => {
      return !report.disableRun;
    }) || []
  );
};

const RunReportModalFeature = ({ onCloseClick, onSubmitClick, initialValues }) => {
  const formRef = useRef();

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [categoryError, setCategoryError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const xemelgoClient = useXemelgoClient();

  const configProvider = useAppConfigProvider(APP_ID);
  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  const { ReportFormComponent } = useReportFormFactory(selectedType, formRef);

  useEffect(() => {
    // window.fcWidget.hide();
    return () => {
      // window.fcWidget.show();
    };
  }, []);

  useEffect(() => {
    const { category, type } = initialValues;
    setSelectedCategory(category || {});
    setSelectedType(type || {});
  }, [initialValues]);

  const possibleCategories = useMemo(() => {
    return Object.keys(reportGroups)
      .map((eachKey) => {
        return { id: eachKey, label: eachKey };
      })
      .filter((category) => {
        return getRunnableReportTypesForCategory(reportGroups, category).length;
      });
  }, []);

  const possibleReportTypes = useMemo(() => {
    if (!selectedCategory) {
      return [];
    }

    return getRunnableReportTypesForCategory(reportGroups, selectedCategory);
  }, [reportGroups, selectedCategory]);

  const validation = () => {
    let isValid = true;
    if (!selectedCategory.id) {
      setCategoryError(true);
      isValid = false;
    }
    if (!selectedType.id) {
      setTypeError(true);
      isValid = false;
    }
    return isValid;
  };

  return (
    <ModalForm
      show
      prefix="run_report_modal"
      className={`${Style.modal} ${Style.flex_column}`}
      title={
        <div className={`${Style.header_container} ${Style.flex_column}`}>
          <button
            type="button"
            className={`${Style.header_close_button} ${Style.flex_row}`}
            onClick={onCloseClick}
          >
            <CloseRounded className={Style.header_close_button_icon} />
          </button>
          <div className={`${Style.header_title_container} ${Style.flex_row}`}>
            <div className={Style.header_title_icon_container}>
              <ReportsIcon
                width={25}
                height={25}
                style={{ color: xemelgoStyle.theme.XEMELGO_BLUE }}
              />
            </div>
            <p>Run Report</p>
          </div>
        </div>
      }
      footer={
        <div className={`${Style.footer_container} ${Style.flex_row}`}>
          <button
            type="button"
            onClick={onCloseClick}
          >
            Discard
          </button>
          <button
            type="button"
            onClick={async () => {
              if (isLoading) {
                return;
              }
              let canSubmit = validation();
              const [reportName, { error: formError, ...param }] = formRef?.current?.buildPayload?.() || [
                "",
                { error: true }
              ];
              if (formError) {
                canSubmit = false;
              }
              if (canSubmit) {
                let submitSuccess = true;
                try {
                  setIsLoading(true);
                  const timestamp = Date.now();
                  const reportClient = xemelgoClient.getReportClient();
                  await reportClient.createReportV2(selectedType.id, `${reportName} - ${timestamp}`, timestamp, param);
                  setIsLoading(false);
                } catch (error) {
                  submitSuccess = false;
                }
                await onSubmitClick(submitSuccess);
                onCloseClick();
              }
            }}
          >
            Submit
          </button>
        </div>
      }
      body={
        <div className={`${Style.body_container}`}>
          {isLoading && (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={Style.loading}
            >
              <LoadingCircle />
            </div>
          )}
          <div className={`${Style.flex_column} ${Style.report_type_section} ${Style.section}`}>
            <p className={`${Style.section_title}`}>Report Type</p>
            <div className={`${Style.grid} ${Style.input_group_list}`}>
              <div className={Style.flex_column}>
                <div className={Style.flex_row}>
                  <p className={`${Style.attribute_label}`}>Category</p>
                  <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
                </div>
                <div className={Style.input_container}>
                  <SearchDropdown
                    color="#4A90FF"
                    error={categoryError}
                    placeholder="Select a category"
                    showIcon
                    withoutOptionFilter
                    selectedItem={selectedCategory}
                    options={possibleCategories}
                    onItemSelected={(item) => {
                      setCategoryError(false);
                      setSelectedType({});
                      setSelectedCategory(item);
                    }}
                  />
                </div>
              </div>
              {selectedCategory?.label && (
                <div className={Style.flex_column}>
                  <div className={Style.flex_row}>
                    <p className={`${Style.attribute_label}`}>Type</p>
                    <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
                  </div>
                  <div className={Style.input_container}>
                    <SearchDropdown
                      color="#4A90FF"
                      error={typeError}
                      placeholder="Select a type"
                      showIcon
                      withoutOptionFilter
                      selectedItem={selectedType}
                      options={possibleReportTypes}
                      onItemSelected={(item) => {
                        setTypeError(false);
                        setSelectedType(item);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedType.label && (
            <div className={`${Style.flex_column} ${Style.section}`}>
              <p className={`${Style.section_title}`}>Report Information</p>
              <ReportFormComponent initialValues={initialValues} />
            </div>
          )}
        </div>
      }
    />
  );
};

RunReportModalFeature.defaultProps = {
  onSubmitClick: () => {},
  onCloseClick: () => {},
  initialValues: {}
};

RunReportModalFeature.propTypes = {
  onSubmitClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  initialValues: PropTypes.shape({
    category: PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
    type: PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
  })
};

export default RunReportModalFeature;
