import React, { createContext, useContext, useMemo } from "react";
import { DEFAULT_STAGE_OPTIONS, DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS, STAGES } from "../../data/constants";
import { useAppConfigProvider } from "../../../../../../../../services/soft-cache-service";
import useKioskSearchParams from "../../../../../../hooks/use-kiosk-search-params";

const TransferOrderConfigContext = createContext();

const SOLUTION_ID = "kiosk";

const initialState = {
  stageOptions: DEFAULT_STAGE_OPTIONS,
  unexpectedScanTableHeaders: DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS,
  useMissingItemModal: true,
  customText: {
    transferOrder: "picklist",
    transferOrderIdentifierLabel: "Tracking #",
    transferOrderIdentifier: "tracking number",
    containerIdentifierLabel: "Pallet #",
    containerIdentifier: "pallet number",
    container: "pallet"
  },
  containerAssociationOptions: {
    enabled: true,
    manualTagEntry: true,
    containerTypeIdentifier: "Pallet",
    existingContainerDisabled: true,
    tagFieldLabel: "Pallet RFID Tag"
  },
  defaultStageId: STAGES.STAGE
};

export const useTransferOrderConfigContext = () => {
  return useContext(TransferOrderConfigContext);
};

export const TransferOrderConfigContextProvider = ({ children }) => {
  const { actionId } = useKioskSearchParams();

  const kioskConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const configValues = useMemo(() => {
    const { config = {} } = kioskConfigProvider;
    const { transferOrderOptions = {} } = config;

    const { actionsMap: newActionsMap = {} } = config;
    const actionConfig = newActionsMap[actionId] || {};
    const { transferOrderOptions: actionTransferOrderOptions = {} } = actionConfig;

    const getConfigValue = (key) => {
      return actionTransferOrderOptions[key] ?? transferOrderOptions[key] ?? initialState[key];
    };

    // Merge custom text from action config with default custom text
    const customText = {
      ...initialState.customText,
      ...getConfigValue("customText")
    };

    return {
      stageOptions: getConfigValue("stageOptions"),
      unexpectedScanTableHeaders: getConfigValue("unexpectedScanTableHeaders"),
      useMissingItemModal: getConfigValue("useMissingItemModal"),
      containerAssociationOptions: getConfigValue("containerAssociationOptions"),
      defaultStageId: getConfigValue("defaultStageId"),
      customText
    };
  }, [kioskConfigProvider, actionId]);

  return <TransferOrderConfigContext.Provider value={configValues}>{children}</TransferOrderConfigContext.Provider>;
};
