import React from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Style from "../../PrinterSelectionComponent.module.css";
import AutoSizeTextArea from "../../../AutoSizeTextArea/AutoSizeTextArea";

export const PrintQuantitySelection = ({ isLoading, printQuantity, onPrintQuantityChange }) => {
  return (
    <div className={Style.body_child_container}>
      <p className={`${xemelgoStyle.subtextBold} ${Style.sub_header}`}>
        Number of Duplicate Tags
        <span className={Style.asterisk}> *</span>
      </p>
      <div className={Style.dropdown_input_container}>
        <AutoSizeTextArea
          value={printQuantity ? `${printQuantity}` : ""}
          onChangeText={(newText) => {
            onPrintQuantityChange(parseInt(newText, 10));
          }}
          numberOnly
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

PrintQuantitySelection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  printQuantity: PropTypes.string.isRequired,
  onPrintQuantityChange: PropTypes.func.isRequired
};
