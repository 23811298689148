import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "mdbreact";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import useAssetTrackPageStateContext from "../../contexts/asset-track-page-state-context";
import FreeTextSearchInput from "../../../../components/free-text-search-input";
import Style from "./TrackPageOptions.module.css";
import TrackPageStyle from "../../AssetTrackPageFeature.module.css";
import { exportCsv } from "../../../../common/Utilities";
import useAssetTrackPageDataSourceContext from "../../contexts/asset-track-page-data-source-context";
import useAssetTrackPageConfigContext from "../../contexts/asset-track-page-config-context";
import ColumnSelectModal from "../../../../components/column-select-modal";
import TabSelector from "./features/tab-selector";
import TableOptions from "./features/table-options";
import { getTextByColumnType } from "../../../../components/paginated-list-table-with-types";

export const TrackPageOptions = () => {
  const history = useHistory();
  const { locationTreeMap } = useAssetTrackPageDataSourceContext();
  const { listTableOptions, maxTableColumns } = useAssetTrackPageConfigContext();
  const {
    selectedLocationId,
    selectedTab,
    searchInput,
    setSearchInput,
    setShowCreateModal,
    displayedData,
    selectedColumns,
    setSelectedColumns
  } = useAssetTrackPageStateContext();
  const [showColumnSelectModal, setShowColumnSelectModal] = useState(false);

  const onExportCsvClick = useCallback(() => {
    const csvData = [];
    const tabSelectedColumns = selectedColumns[selectedTab] || [];
    const locationName = locationTreeMap[selectedLocationId]?.name || "all";

    displayedData.forEach((group) => {
      const location = locationTreeMap[group.id].name;
      group.dataList.forEach((asset) => {
        const rowData = { Location: location };
        tabSelectedColumns.forEach((column) => {
          rowData[column.label] = getTextByColumnType(asset[column.id], column.type, column.timeFormat, true);
        });
        csvData.push(rowData);
      });
    });

    exportCsv(
      csvData,
      {
        header: [
          "Location",
          ...tabSelectedColumns.map((column) => {
            return column.label;
          })
        ],
        cellDates: true
      },
      `${locationName}-${selectedTab}s.csv`
    );
  }, [displayedData, locationTreeMap, selectedLocationId, selectedColumns]);

  return (
    <>
      <div
        className={`${TrackPageStyle.flex_row} ${Style.options_container}`}
        data-cy="asset-track-options"
      >
        <button
          type="button"
          className={`${Style.button} ${Style.blue_button}`}
          onClick={() => {
            // window.fcWidget.hide();
            setShowCreateModal(true);
          }}
        >
          + Add Asset
        </button>
        <div className={Style.free_text_search_container}>
          <FreeTextSearchInput
            value={searchInput}
            onChangeText={setSearchInput}
            searchInputClassName={Style.free_text_search_input}
          />
        </div>
        <button
          type="button"
          className={`${Style.button} ${Style.white_button} ${Style.export_button}`}
          onClick={() => {
            onExportCsvClick();
          }}
        >
          <ExportIcon className={Style.export_icon} />
          Export
        </button>
        <Dropdown>
          <DropdownToggle
            nav
            className={`${Style.button} ${Style.white_button} ${Style.more_options_button}`}
            data-cy="more-options-button"
          >
            <MoreIcon className={Style.more_options_icon} />
          </DropdownToggle>
          <DropdownMenu className={Style.more_options_dropdown}>
            <DropdownItem value="Type">
              <Link to={`${history.location.pathname}/bulk-update`}>Bulk Update</Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className={`${TrackPageStyle.flex_row} ${Style.options_container}`}>
        <TabSelector />
        <TableOptions
          onColumnSelectClick={() => {
            setShowColumnSelectModal(true);
          }}
        />
      </div>
      <ColumnSelectModal
        columnOptions={listTableOptions[selectedTab].headers || []}
        currentColumns={selectedColumns[selectedTab] || []}
        onConfirm={(columns) => {
          setShowColumnSelectModal(false);
          setSelectedColumns({
            ...selectedColumns,
            [selectedTab]: columns
          });
        }}
        onCancel={() => {
          setShowColumnSelectModal(false);
        }}
        show={showColumnSelectModal}
        maxColumns={maxTableColumns}
      />
    </>
  );
};
