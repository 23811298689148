import React from "react";
import PropTypes from "prop-types";
import { TransferOrderConfigContextProvider } from "./contexts/transfer-order-config-context";
import { ACTION_OPTIONS_MAP } from "../../../../data/constants";
import OrderCreation from "./features/order-creation";
import PicklistVerification from "./features/picklist-verification";

export const TransferOrderActions = ({ actionType }) => {
  return (
    <TransferOrderConfigContextProvider>
      {actionType === ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION ? <PicklistVerification /> : <OrderCreation />}
    </TransferOrderConfigContextProvider>
  );
};

TransferOrderActions.propTypes = {
  actionType: PropTypes.string.isRequired
};
